import { keyframes, styled } from '@grupoboticario/flora-react';

const skeletonLoading = keyframes({
  '0%': {
    backgroundPosition: '-200px 0',
  },
  '100%': {
    backgroundPosition: 'calc(200px + 100%) 0',
  },
});

export const SkeletonItem = styled('span', {
  animation: `${skeletonLoading} 1s cubic-bezier(0.4, 0, 1, 1) infinite`,
  backgroundColor: '#eee',
  backgroundImage: 'linear-gradient(90deg, #eee, #fff, #eee)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '200px 100%',
  borderRadius: '$medium',
  color: 'rgba(0,0,0,0)',
  display: 'inline-block',
  lineHeight: '1',
  width: '100%',
});
