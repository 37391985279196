import { useReactTable, ColumnDef, getCoreRowModel, createColumnHelper, flexRender } from '@tanstack/react-table';
import {
  Card,
  CardTitle,
  StyledTable,
  StyledTd,
  StyledTh,
  ToggleButton,
  TableSkeleton,
  TableCellText,
  InactivityLevelName,
  GbClubLevelName,
} from '@/shared/components';
import { tableColumnsLabelMap } from '@/shared/format';
import { Flex, ScrollArea } from '@grupoboticario/flora-react';
import { useEffect, useState } from 'react';

type ColumnHelperMetaType = {
  isHidden?: boolean;
};
interface CellData {
  code?: string;
  value: number | string;
  percentage?: number | string;
}

interface TableData {
  classification: string;
}

const Table = ({
  data,
  isInactivityTable = false,
  isActivityDashboard = false,
  title,
  isLoading = true,
  format,
  brandTitle = '',
  channelTitle = '',
  tooltipText,
}) => {
  const [selectedToggle, setSelectedToggle] = useState('channel');
  const [tableData, setTableData] = useState([]);

  const allColumns = new Set<string>(data?.flatMap(item => Object.keys(item)));

  const toggableColumnsNames = Array.from(
    new Set<string>(data?.flatMap(item => item[selectedToggle]?.map(({ code }) => code))),
  ).filter(code =>
    isActivityDashboard
      ? code !== undefined && code !== 'appSuper' && code !== 'portal' && code !== 'multiB'
      : code !== undefined,
  );

  const fixedHeaders = Array.from(allColumns).filter(key => {
    return Array.isArray(data[0][key]) && key !== 'channel' && key !== 'brand';
  });

  const fixedColumnsWithHeaderNames = Array.from(
    new Set<string>(
      data?.flatMap(item =>
        Object.keys(item)
          .filter(key => key !== 'channel' && key !== 'brand')
          .flatMap(key => item[key]?.map?.(({ code }) => code)),
      ),
    ),
  ).filter(code => code !== undefined);

  const fixedColumnsWithoutHeaderNames = Array.from(allColumns).filter(key => {
    return !Array.isArray(data[0][key]) && key !== 'code';
  });

  const columnHelper = createColumnHelper<TableData>();

  function generateColumnsAccessor(code) {
    return columnHelper.accessor(code, {
      header: code,
      cell: props => {
        const value = props.getValue() as CellData;
        const isBase = props.column.id === 'base' || props.column.id === 'activeBase' || props.column.id === 'revenue';

        return (
          <TableCellText
            isBase={isBase}
            isChannelOrBrand={props.column.parent.id === 'togglableColumn'}
            isActivityDashboard={isActivityDashboard}
            format={format}
            displayValue={value?.value}
            percentage={value?.percentage}
            textPercentage={props.row.original.classification}
          />
        );
      },
    });
  }

  const classificationColumn = {
    id: 'classification',
    meta: {
      isHidden: true,
    },
    columns: [
      columnHelper.accessor('classification', {
        header: 'Classificação',
        cell: props => {
          return isInactivityTable ? (
            <InactivityLevelName level={props.getValue()} />
          ) : (
            <GbClubLevelName level={props.getValue()} />
          );
        },
      }),
    ],
  };

  const fixedColumns = {
    id: 'fixedColumns',
    meta: {
      isHidden: true,
    },
    columns: fixedColumnsWithoutHeaderNames.map(column => generateColumnsAccessor(column)),
  };

  const headerColumns = {
    id: 'headerColumn',
    header: 'Atividade',
    columns: fixedColumnsWithHeaderNames.map(column => generateColumnsAccessor(column)),
  };

  const togglableColumns = {
    id: 'togglableColumn',
    header: selectedToggle === 'brand' ? brandTitle : channelTitle,
    columns: toggableColumnsNames.map(code => generateColumnsAccessor(code)),
  };

  const Columns: ColumnDef<TableData>[] = [classificationColumn, fixedColumns, headerColumns, togglableColumns].filter(
    item => item.columns.length > 0,
  );

  useEffect(() => {
    if (data) {
      const tableData = data?.map(item => {
        const channels = {};
        const fixedColumnsWithHeader = {};
        const fixedColumnsWithoutHeader = {};

        toggableColumnsNames?.forEach(code => {
          channels[code] = item[selectedToggle]?.find(value => value?.code === code);
        });

        fixedColumnsWithHeaderNames?.forEach(code => {
          fixedColumnsWithHeader[code] = item[fixedHeaders[0]]?.find(value => value?.code === code);
        });

        fixedColumnsWithoutHeaderNames?.forEach(column => {
          fixedColumnsWithoutHeader[column] = item[column];
        });

        return {
          classification: item.code,
          ...fixedColumnsWithoutHeader,
          ...fixedColumnsWithHeader,
          ...channels,
        };
      });
      setTableData(tableData);
    }
  }, [selectedToggle, data]);

  const table = useReactTable<TableData>({
    data: tableData,
    columns: Columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card css={{ gridArea: isInactivityTable ? 'inactivity-level' : 'my-gb-club' }}>
      <Flex
        css={{
          flexDirection: 'column',
          '@tablet': { flexDirection: 'row' },
        }}
        justify="space-between"
      >
        <CardTitle title={title} tooltip={tooltipText} />
        {allColumns.has('channel') && allColumns.has('brand') && (
          <ToggleButton
            leftText="Meio de captação"
            onLeftButtonClick={() => setSelectedToggle('channel')}
            rightText="Marcas"
            onRightButtonClick={() => setSelectedToggle('brand')}
          />
        )}
      </Flex>
      <ScrollArea css={{ scrollHeight: '4px', paddingBottom: '20px' }} orientation="horizontal" type="auto">
        <StyledTable>
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <>
              <thead>
                {table.getHeaderGroups().map(headerGroup => {
                  const hasParentHeader = fixedHeaders.length > 0 || toggableColumnsNames.length > 0;
                  if (!hasParentHeader && headerGroup.id === '0') {
                    return;
                  }
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        const meta: ColumnHelperMetaType = header.column.columnDef.meta;

                        const isClassificationColumn = header.column.id === 'classification';
                        const isTogglableColumn = header.column.id === 'togglableColumn';
                        const isHeaderColumn = header.column.id === 'headerColumn';

                        const headerColumnChildren = headerGroup.headers.filter(
                          header => header.column.parent?.id === 'headerColumn',
                        );
                        const isLastHeaderColumnChildren =
                          headerColumnChildren[headerColumnChildren.length - 1]?.id === header.id;

                        return (
                          <StyledTh
                            css={{
                              borderBottom: meta?.isHidden ? 'none' : '1px solid #ccc',
                              borderRight: isHeaderColumn || isLastHeaderColumnChildren ? '1px solid #ccc' : 'none',
                              textAlign:
                                isHeaderColumn || isTogglableColumn
                                  ? 'center'
                                  : isClassificationColumn
                                    ? 'left'
                                    : 'right',
                            }}
                            key={header.id}
                            colSpan={header.colSpan}
                          >
                            {flexRender(
                              tableColumnsLabelMap[header.column.columnDef.header.toString()] ||
                                header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </StyledTh>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      const headerColumnChildren = cell.column.parent?.columns.filter(
                        column => column.parent?.id === 'headerColumn',
                      );
                      const isLastHeaderColumnChildren =
                        headerColumnChildren[headerColumnChildren.length - 1]?.id === cell.column.id;

                      const isClassificationRow = cell.row.original.classification === 'Total';

                      return (
                        <StyledTd
                          css={{
                            borderRight: isLastHeaderColumnChildren ? '1px solid #ccc' : 'none',
                            borderBottom: isClassificationRow ? 'none' : '1px solid #ccc',
                          }}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </StyledTd>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </StyledTable>
      </ScrollArea>
    </Card>
  );
};

export { Table };
