import {
  ChartKind,
  Indicator,
  TimeSeriesOverviewCyclesData,
  generateSerie,
  updateSeriesData,
  useFilters,
  formatTimeSeriesValues,
  generateCycleData,
} from '@/features';
import { ReactEChartsProps, defaultColorPalette } from '@/shared/components';
import { generateTimeSeriesTooltip } from '@/shared/format';
import { EChartsOption } from 'echarts';
import { useEffect, useState } from 'react';

interface TimeSeriesChartParams {
  cyclesData: TimeSeriesOverviewCyclesData[];
  indicator: Indicator;
  chartKinds: ChartKind[];
  selectedCycles: string[];
}

const { lightBlue, blue, green } = defaultColorPalette;

export function useTimeSeriesChart({ cyclesData, indicator, chartKinds, selectedCycles }: TimeSeriesChartParams) {
  const { cycles } = useFilters();
  const [displayedChart, setDisplayedChart] = useState<ChartKind>();
  const [series, setSeries] = useState<EChartsOption['series']>([]);

  const deviceType = window.innerWidth < 768 ? 'mobile' : window.innerWidth <= 1024 ? 'tablet' : 'desktop';

  const leftGridSize = deviceType === 'desktop' ? '8%' : deviceType === 'tablet' ? '11%' : '17%';

  const latestCycle = cycles[0].code.toString();

  const chartData = generateCycleData({
    cyclesData,
    indicator,
    latestCycle,
    results: ['achievement', 'goal', 'realized', 'invoicing'],
  });

  const { achievementSeriesData, goalSeriesData, realizedSeriesData } = chartData;

  const achievementCategories = [
    {
      name: 'Atingimento',
      data: achievementSeriesData.previous,
      xAxisIndex: 0,
      color: blue,
    },
    {
      name: 'Realizado do Ciclo Atual',
      data: achievementSeriesData.actual,
      xAxisIndex: 1,
      color: green,
    },
  ];

  const detailCategories = [
    {
      name: 'Meta',
      data: goalSeriesData.previous,
      xAxisIndex: 2,
      color: lightBlue,
    },
    {
      name: 'Realizado',
      data: realizedSeriesData.previous,
      xAxisIndex: 2,
      color: blue,
    },
    {
      name: 'Meta',
      data: goalSeriesData.actual,
      xAxisIndex: 3,
      color: lightBlue,
    },
    {
      name: 'Realizado do ciclo atual',
      data: realizedSeriesData.actual,
      xAxisIndex: 3,
      color: green,
    },
  ];

  const achievementSeries = achievementCategories.map(category => generateSerie({ ...category, displayedChart }));

  const detailSeries = detailCategories.map(category => generateSerie({ ...category, displayedChart }));

  const allSeries = [...achievementSeries, ...detailSeries];

  const isActualCycleSelected = selectedCycles.includes(latestCycle);

  useEffect(() => {
    if (!displayedChart) {
      return setDisplayedChart(chartKinds[0]);
    }

    if (displayedChart === 'achievement') {
      setSeries(updateSeriesData(achievementSeries, allSeries, isActualCycleSelected));
    }

    if (displayedChart !== 'achievement') {
      setSeries(updateSeriesData(detailSeries, allSeries, isActualCycleSelected));
    }
  }, [displayedChart, cyclesData]);

  const selectedCycleAscendingOrder = [...selectedCycles].sort((a, b) => Number(a) - Number(b));

  const xAxis: ReactEChartsProps['option']['xAxis'] = [
    {
      type: 'category',
      data: selectedCycleAscendingOrder,
    },
    {
      type: 'category',
      show: false,
      data: selectedCycleAscendingOrder,
    },
    {
      type: 'category',
      show: false,
      data: selectedCycleAscendingOrder,
    },
    {
      type: 'category',
      show: false,
      data: selectedCycleAscendingOrder,
    },
  ];

  const options: ReactEChartsProps['option'] = {
    grid: {
      top: '4%',
      right: '8%',
      bottom: '16%',
      left: leftGridSize,
    },
    legend: { itemWidth: 14, itemHeight: 14, bottom: 0, icon: 'roundRect' },
    yAxis: {
      axisLabel: {
        formatter: value => {
          if (value === 0) {
            return '0';
          }
          if (value < 1000000) {
            return formatTimeSeriesValues(value, displayedChart);
          }
          return `${value / 1000000} mi`;
        },
      },
    },
    xAxis,
    series,
    tooltip: {
      trigger: 'axis',
      borderRadius: 6,
      borderColor: '#fff',
      axisPointer: {
        type: 'shadow',
      },
      formatter: value => {
        return generateTimeSeriesTooltip({ value, displayedChart });
      },
    },
  };

  return { options, setDisplayedChart, displayedChart };
}
