import { useFilters } from '@/features/MainDashboardFilters';
import { Flex } from '@grupoboticario/flora-react';
import { TimeSeriesChart } from '@/features/TimeSeries';
import { useGetDashboardData } from '@/shared/hooks';

export function OverviewTimeSeries() {
  const {
    requestData: { cycle },
  } = useFilters();

  const selectedCycles = `${cycle}`.split(',');

  const { data, isLoading } = useGetDashboardData({
    queryKey: 'overviewTimeSeries',
    path: '/overview/timeseries',
  });

  return (
    <Flex direction="column" gap="$8">
      <TimeSeriesChart
        chartKinds={['achievement', 'detail']}
        indicator={'totalBase'}
        selectedCycles={selectedCycles}
        cyclesData={data ?? []}
        isLoading={isLoading}
      />
      <TimeSeriesChart
        chartKinds={['achievement', 'detail']}
        indicator={'actives'}
        selectedCycles={selectedCycles}
        cyclesData={data ?? []}
        isLoading={isLoading}
      />
      <TimeSeriesChart
        chartKinds={['achievement', 'detail_currency_million']}
        indicator={'revenue'}
        selectedCycles={selectedCycles}
        cyclesData={data ?? []}
        isLoading={isLoading}
      />
      <TimeSeriesChart
        chartKinds={['achievement', 'detail_currency']}
        indicator={'productivity'}
        selectedCycles={selectedCycles}
        cyclesData={data ?? []}
        isLoading={isLoading}
      />
    </Flex>
  );
}
