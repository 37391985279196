import { CSS, Grid } from '@grupoboticario/flora-react';

export interface DashboardGridProps {
  children: React.ReactNode;
  css?: CSS &
    Required<{
      '@mobile': CSS['@mobile'];
    }>;
}

export const DashboardGrid = ({ css, children }: DashboardGridProps) => {
  return (
    <Grid
      gap="$4"
      css={{
        marginTop: '$4',
        ...css['@mobile'],
        ...css,
      }}
    >
      {children}
    </Grid>
  );
};
