import { IconCardIndicator, DashboardGrid, Table } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { gridSettings } from './grid-settings';
import { decimal, decimalNoFraction, percent } from '@/shared/format';
import { ThreePeopleIcon, CheckSingleIcon, CheckDoubleIcon, StoreIcon } from '@grupoboticario/flora-react-icons';

const channelTitle = 'Representatividade das ativas por meio de captação';
const brandTitle = 'Representatividade das ativas por marca';

export function ActivityDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'activity', path: '/activity/dashboard' });

  return (
    <DashboardGrid css={gridSettings}>
      <IconCardIndicator
        title="Base ativa - FV"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido nos 6 ciclos anteriores ao ciclo selecionado (de A0 a I6), excluindo Inícios e Reinícios."
        gridArea="total-base"
        icon={<ThreePeopleIcon />}
        data={data?.totalBase}
        format={decimal.format}
        isLoading={isLoading}
      />
      <IconCardIndicator
        title="Revendedores Ativos - FV"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido no ciclo selecionado."
        gridArea="active"
        icon={<CheckSingleIcon />}
        data={data?.actives}
        format={decimal.format}
        isLoading={isLoading}
      />
      <IconCardIndicator
        title="Atividade - FV"
        tooltip="Percentual de revendedores da base ativa, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido no ciclo selecionado."
        gridArea="activity"
        icon={<CheckDoubleIcon />}
        data={data?.activity}
        format={percent.format}
        isLoading={isLoading}
      />
      <IconCardIndicator
        title="Revendedores ativos multimarca - FV"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido com duas ou mais marcas (Boti, Eud, QDB, OUI e Pets), no ciclo selecionado."
        gridArea="active-mm"
        icon={<CheckSingleIcon />}
        data={data?.activesMm}
        format={decimal.format}
        isLoading={isLoading}
      />
      <IconCardIndicator
        title="Revendedores no Espaço do Revendedor - FV"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido no Espaço do Revendedor no ciclo selecionado."
        gridArea="active-er"
        icon={<StoreIcon />}
        data={data?.activesEr}
        format={decimal.format}
        isLoading={isLoading}
      />
      <Table
        title="Nível de inatividade"
        tooltipText="Quantidade de revendedores da base ativa e revendedores ativos, gerenciados pela Força de Vendas, separados pelas marcas O Boticário, Eudora e Multimarcas. Detalhamento por atividade em cada um das marcas ou por meio de captação, de acordo com o nível de inatividade no ciclo selecionado."
        isInactivityTable
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        format={decimalNoFraction.format}
        isLoading={isLoading}
        data={data?.activityPerInactivityLevel}
        isActivityDashboard
      />
      <Table
        title="Meu clube GB - FV"
        tooltipText="Quantidade de revendedores da base ativa e revendedores ativos, gerenciados pela Força de Vendas, separados pelas marcas O Boticário, Eudora e Multimarcas. Detalhamento por atividade em cada um das marcas ou por meio de captação, de acordo com a faixa no Meu Clube GB no ciclo selecionado."
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        format={decimalNoFraction.format}
        isLoading={isLoading}
        data={data?.activityPerMeuClubeGBLevel}
        isActivityDashboard
      />
    </DashboardGrid>
  );
}
