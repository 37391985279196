import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
     "revenue-gmv revenue-gmv"
     "revenue-rbv revenue-rbv"
     "revenue-pvr revenue-pvr"
     "volume-orders volume-orders"
     "first-order first-order"
     "subsequent-order subsequent-order"
     "canceled-orders canceled-orders"
     "invoice-orders invoice-orders"
     "average-order average-order"
     "upa upa"
     "productivity productivity"
     "inactivity-level inactivity-level"
     "my-gb-club my-gb-club"
     "donut-chart donut-chart"
     "column-chart column-chart"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
     "revenue-gmv revenue-gmv"
     "revenue-rbv revenue-rbv"
     "revenue-pvr revenue-pvr"
     "volume-orders first-order"
     "subsequent-order canceled-orders"
     "invoice-orders average-order"
     "upa productivity"
     "inactivity-level inactivity-level"
     "my-gb-club my-gb-club"
     "donut-chart donut-chart"
     "column-chart column-chart"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
      "revenue-gmv revenue-gmv revenue-gmv revenue-gmv revenue-gmv revenue-rbv revenue-rbv revenue-rbv revenue-rbv revenue-rbv revenue-pvr revenue-pvr revenue-pvr revenue-pvr revenue-pvr"
      "volume-orders volume-orders volume-orders first-order first-order first-order subsequent-order subsequent-order subsequent-order canceled-orders canceled-orders canceled-orders invoice-orders invoice-orders invoice-orders"
      "average-order average-order average-order average-order average-order upa upa upa upa upa productivity productivity productivity productivity productivity"
      "inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level"
      "my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club"
      "donut-chart donut-chart donut-chart donut-chart column-chart column-chart column-chart column-chart column-chart column-chart column-chart column-chart column-chart column-chart column-chart"
    `,
    gridTemplateColumns: 'repeat(15, 1fr)',
  },
};
