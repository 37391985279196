import { authService } from '@vdi/auth-service';

export const fetchFilters = async ({ signal, cycle }) => {
  const { token } = await authService.getTokenOrRedirect();

  const url = `${process.env.API_BASE_URL}/filters/sectors?cycle=${cycle}`;

  const resp = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    signal,
  });

  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }

  return resp.json();
};
