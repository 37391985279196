import { Card, CardTitle, ChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { decimalNoFraction, percentNoFraction, relationTooltip } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';
import { lighten, mix } from 'polished';

function scale(baseColor, scaleSize) {
  if (baseColor === '#596D79') {
    return Array.from({ length: scaleSize }, (_, i) => mix(1 - i / scaleSize, baseColor, 'white'));
  }

  const lightenColors = Array.from({ length: scaleSize - 1 }, (_, i) =>
    mix(1 - (i + 1) / scaleSize, baseColor, lighten(0.5, baseColor)),
  );

  return [baseColor, ...lightenColors];
}

export function RelationBarChart({ chartData, chartTitle, gridArea, isLoading, tooltip, baseColor }) {
  const isMobile = window.innerWidth < 768;
  const isTablet = window.innerWidth < 992;

  const dataInAscedingOrder = chartData?.sort((a, b) => b.resellers - a.resellers);

  const colors = scale(baseColor, dataInAscedingOrder?.length);

  const options: ReactEChartsProps['option'] = {
    dataset: [
      {
        dimensions: ['name', 'resellers', 'percentage', 'code'],
        source:
          dataInAscedingOrder?.map(column => [column.name, column.resellers, column.percentage, column.code]) ?? [],
      },
    ],
    xAxis: {
      show: !isMobile,
      type: 'category',
      axisLabel: {
        overflow: 'break',
        interval: 0,
        width: isTablet ? 80 : 150,
        margin: 16,
        rich: {
          span: {
            fontWeight: 'bold',
            padding: [4, 0],
          },
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: value => decimalNoFraction.format(value),
      },
    },
    series: {
      type: 'bar',
      colorBy: 'data',
      itemStyle: {
        color: params => colors[params.dataIndex] || '#f0f0f0',
      },
      label: {
        show: !isMobile,
        position: 'inside',
        formatter: value => percentNoFraction.format(value.data[2] / 100),
        rich: {
          div: {
            align: 'center',
            lineHeight: 16,
          },
        },
      },
    },
    grid: {
      top: '10%',
      right: '2%',
      bottom: '2%',
      left: '2%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'item',
      borderWidth: 0,
      confine: true,
      formatter: value => relationTooltip(chartTitle, value.name, value.marker, value.data[1], value.data[2]),
    },
  };

  return (
    <Card css={{ gridArea: gridArea }}>
      <CardTitle title={chartTitle} tooltip={tooltip} />
      <Flex justify="space-evenly" direction="column">
        <ReactECharts
          option={{ ...options }}
          style={{
            width: '100%',
            height: '360px',
          }}
          loading={isLoading}
        />
        {isMobile && dataInAscedingOrder && <ChartLegend columns={dataInAscedingOrder} colors={colors} />}
      </Flex>
    </Card>
  );
}
