import { Checkbox } from '@grupoboticario/flora-react';
import { useEffect, useState } from 'react';

export function FilterCheckbox({ selected, id }) {
  const [checkboxStatus, setCheckboxStatus] = useState(selected);

  useEffect(() => {
    if (selected === true) {
      setCheckboxStatus('selected');
    }
    if (selected === false) {
      setCheckboxStatus('unselected');
    }
    if (selected === 'indeterminate') {
      setCheckboxStatus('indeterminate');
    }
  }, [selected]);

  return (
    <Checkbox
      css={{
        boxShadow:
          checkboxStatus === 'indeterminate' || checkboxStatus === 'selected'
            ? 'inset 0 0 0 2px #264FEC'
            : 'inset 0 0 0 2px rgba(102, 102, 102, 1)',
        width: '20px',
        minWidth: '20px',
        height: '20px',
        color: checkboxStatus === 'indeterminate' ? '$actionableDefault' : '#ffffff',
        backgroundColor:
          checkboxStatus === 'indeterminate' || checkboxStatus === 'unselected' ? 'transparent' : '$actionableDefault',
        '&:hover': {
          backgroundColor:
            checkboxStatus === 'indeterminate' || checkboxStatus === 'unselected' ? 'transparent' : '$actionableHover',
        },
        '&:hover > span': {
          color: checkboxStatus === 'indeterminate' ? '$actionableHover' : '#ffffff',
        },
      }}
      color="primary"
      checked={selected}
      id={id}
    />
  );
}
