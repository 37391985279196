import { ActiveResellersChart, DashboardGrid, IconCardIndicator } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { gridSettings } from './grid-settings';
import { decimal, percent, currency } from '@/shared/format';
import {
  ThreePeopleIcon,
  CheckSingleIcon,
  CheckDoubleIcon,
  StoreIcon,
  DollarSignCircleIcon,
  LineChartIcon,
  ViewFourSquareGridIcon,
} from '@grupoboticario/flora-react-icons';
export function OverviewDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'overview', path: '/overview/dashboard' });

  return (
    <>
      <DashboardGrid css={gridSettings}>
        <IconCardIndicator
          title="Base ativa - FV"
          tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido nos 6 ciclos anteriores ao ciclo selecionado (de A0 a I6), excluindo Inícios e Reinícios."
          gridArea="total-base"
          icon={<ThreePeopleIcon />}
          data={data?.totalBase}
          format={decimal.format}
          isLoading={isLoading}
        />
        <IconCardIndicator
          title="Atividade - FV"
          tooltip="Percentual de revendedores da base ativa, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido no ciclo selecionado."
          gridArea="activity"
          icon={<CheckDoubleIcon />}
          data={data?.activity}
          format={percent.format}
          isLoading={isLoading}
        />

        <IconCardIndicator
          title="Revendedores Ativos - FV"
          tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido no ciclo selecionado."
          gridArea="active"
          icon={<CheckSingleIcon />}
          data={data?.actives}
          format={decimal.format}
          isLoading={isLoading}
        />
        <IconCardIndicator
          title="Revendedores ativos multimarca - FV"
          tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido com duas ou mais marcas (Boti, Eud, QDB, OUI e Pets), no ciclo selecionado."
          gridArea="active-mm"
          icon={<CheckSingleIcon />}
          data={data?.activesMm}
          format={decimal.format}
          isLoading={isLoading}
        />
        <IconCardIndicator
          title="Revendedores no Espaço do Revendedor - FV"
          tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido no Espaço do Revendedor no ciclo selecionado."
          gridArea="active-er"
          icon={<StoreIcon />}
          data={data?.activesEr}
          format={decimal.format}
          isLoading={isLoading}
        />
        <IconCardIndicator
          title="Receita - FV"
          tooltip="Valor total dos pedidos realizados pelos revendedores, gerenciados pela Força de Vendas, no ciclo selecionado. O valor é composto pela Receita Bruta de Vendas (RBV)."
          gridArea="revenue"
          icon={<DollarSignCircleIcon />}
          data={data?.revenue}
          isLoading={isLoading}
          format={currency.format}
        />
        <IconCardIndicator
          title="Produtividade - FV"
          tooltip="Valor médio dos pedidos realizados pelos revendedores, gerenciados pela Força de Vendas, no ciclo selecionado."
          gridArea="productivity"
          icon={<LineChartIcon />}
          data={data?.productivity}
          isLoading={isLoading}
          format={currency.format}
        />
        <IconCardIndicator
          title="Bloco"
          tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, categorizados entre A3 (ativos há 3 ciclos) e I5 (inativos há 5 ciclos) no ciclo selecionado."
          gridArea="block"
          icon={<ViewFourSquareGridIcon />}
          data={data?.block}
          format={decimal.format}
          isLoading={isLoading}
        />
        <ActiveResellersChart chart={data?.activesGraph} isLoading={isLoading} />
      </DashboardGrid>
    </>
  );
}
