import { authService } from '@vdi/auth-service';

export async function fetchCycles() {
  const { token } = await authService.getTokenOrRedirect();
  const url = `${process.env.API_BASE_URL}/filters/cycles`;

  const resp = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!resp.ok) {
    throw new Error('Network response was not ok');
  }

  return resp.json();
}
