import { Card, DashboardGrid, DashboardGridProps } from '@/shared/components';

export const gridSettings: DashboardGridProps['css'] = {
  marginTop: 0,
  '@mobile': {
    gridTemplateAreas: `
    "stacked-bar-chart"
    "donut-chart"
    `,
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
    "stacked-bar-chart stacked-bar-chart stacked-bar-chart stacked-bar-chart"
    "donut-chart donut-chart donut-chart donut-chart"
    `,
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
    "stacked-bar-chart stacked-bar-chart stacked-bar-chart stacked-bar-chart stacked-bar-chart stacked-bar-chart donut-chart donut-chart donut-chart donut-chart donut-chart donut-chart"
    `,
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
};

export function AttendanceGraphsCard({ gridArea, stackedBarChart, donutChart }) {
  return (
    <Card css={{ gridArea: gridArea }}>
      <DashboardGrid css={gridSettings}>
        {{ ...stackedBarChart }}
        {{ ...donutChart }}
      </DashboardGrid>
    </Card>
  );
}
