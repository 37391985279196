import { Flex, Text } from '@grupoboticario/flora-react';

export const ChartLegend = ({ columns, colors }) => {
  return (
    <Flex align="center" justify="center" wrap="wrap" gap="$2">
      {columns?.map((item, index) => {
        return (
          <Flex gap="$1" key={index} direction="row" align="center">
            <div
              style={{
                backgroundColor: colors[index],
                minWidth: '16px',
                minHeight: '16px',
                borderRadius: '4px',
              }}
            />
            <Text css={{ fontSize: '12px' }}>{item.name}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
