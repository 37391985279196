import { SkeletonItem, StyledTd, StyledTh } from '@/shared/components';

export function TableSkeleton() {
  const tableColumns = Array.from({ length: 3 }, (_, index) => index);
  const tableRows = Array.from({ length: 6 }, (_, index) => index);

  return (
    <>
      <thead>
        <tr>
          {tableColumns.map(column => (
            <StyledTh key={`column-${column}`}>
              <SkeletonItem>&nbsp;</SkeletonItem>
            </StyledTh>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows.map(row => (
          <tr style={{ borderBottom: '1px solid $nonInteractiveAltAuxiliar' }} key={`row-${row}`}>
            {tableColumns.map(column => (
              <StyledTd key={`column-${column}`}>
                <SkeletonItem>&nbsp;</SkeletonItem>
              </StyledTd>
            ))}
          </tr>
        ))}
      </tbody>
    </>
  );
}
