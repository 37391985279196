import { createContext, useContext, useReducer } from 'react';

export const SubreasonContext = createContext(null);
export const SubreasonDispatchContext = createContext(null);

interface SubreasonState {
  reasons: { name: string; color: string }[];
  selectedReason: string;
  selectedReasonColor: string;
}

const initialState: SubreasonState = {
  reasons: [],
  selectedReason: '',
  selectedReasonColor: '',
};

type SetChartData = {
  type: 'SET_CHART_DATA';
  payload: {
    data: { name: string; code: string }[];
  };
};

type SelectReason = {
  type: 'SELECT_REASON';
  payload: {
    reason: string;
  };
};

type SubreasonActions = SetChartData | SelectReason;

function reducer(state: SubreasonState, action: SubreasonActions) {
  switch (action.type) {
    case 'SET_CHART_DATA': {
      const { data } = action.payload;
      const reasonColorLabelMap = {
        '648b6d054282fc518e11fd14': '#011E38', // venda
        '667026e70d24c394b2435c91': '#4A5DA3', // promessa de compra
        '667026e80d24c394b2435c97': '#A0C5D9', // relacionamento e pós-venda
        '667026ea0d24c394b2435ca0': '#52886D', // recusa de venda
        '667026ec0d24c394b2435cac': '#7DBE78', // solicitações ou dúvidas
        '648b6ecb4282fc518e11fd6f': '#CFCED3', // outros
      };

      const chartData = data?.map(column => ({
        name: column.name,
        color: reasonColorLabelMap[column.code] || '#F4B63F',
      }));

      const selectedReason = chartData[0]?.name;
      const selectedReasonColor = chartData[0]?.color;

      const reasonsNames = chartData.map(reason => reason.name);

      return {
        ...state,
        reasons: chartData,
        reasonsNames,
        selectedReason,
        selectedReasonColor,
      };
    }
    case 'SELECT_REASON': {
      const { reason: selectedReason } = action.payload;
      const selectedReasonIndex = state.reasons.findIndex(reason => reason.name === selectedReason);
      const selectedReasonColor = state.reasons[selectedReasonIndex].color;
      return {
        ...state,
        selectedReason,
        selectedReasonColor,
      };
    }
    default:
      return state;
  }
}

export function useSubreason() {
  return useContext(SubreasonContext);
}

export function useSubreasonDispatch() {
  return useContext(SubreasonDispatchContext);
}

export function SubreasonProvider({ children }) {
  const [filters, dispatch] = useReducer(reducer, initialState);
  return (
    <SubreasonContext.Provider value={filters}>
      <SubreasonDispatchContext.Provider value={dispatch}>{children}</SubreasonDispatchContext.Provider>
    </SubreasonContext.Provider>
  );
}
