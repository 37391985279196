import { Card, CardTitle, ChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { decimalNoFraction, formatTooltipBar, brandsLabelMap } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';

interface ColumnChartProps {
  gridArea: string;
  data: { code: string; value: number }[];
  isLoading: boolean;
}

const chartTitle = 'Receita GMV - Por marca';

export function ColumnChart({ gridArea, data, isLoading }: ColumnChartProps) {
  const isMobile = window.innerWidth < 680;

  const columnsCustomOrder = data?.sort((a, b) => b.value - a.value);

  const colorMap = { oui: '#971B2F', eud: '#613A53', boti: '#00A775', qdb: '#D6d1ca', others: '#596D79' };

  const colors = columnsCustomOrder?.map(item => colorMap[item.code]);

  const chartData = columnsCustomOrder?.map(item => ({
    name: brandsLabelMap[item.code] || item.code,
    value: item.value,
    ...item,
  }));

  const chartSeries: ReactEChartsProps['option']['series'] = chartData?.map(column => ({
    type: 'bar',
    seriesLayoutBy: 'row',
    itemStyle: { color: colorMap[column.code] },
    name: column.code,
    data: [{ code: column.code, value: column.value, name: brandsLabelMap[column.code] || column.code }],
    label: {
      show: !isMobile,
      position: 'inside',
      formatter: ({ value }) => decimalNoFraction.format(Number(value)),
      fontSize: 14,
      textBorderColor: 'inherit',
      textBorderWidth: 3,
    },
  }));

  return (
    <Card css={{ gridArea: gridArea, height: '100%' }}>
      <CardTitle
        title={chartTitle}
        tooltip="Receita GMV originada pelas principais marcas (EUD, O Boticário, QDB e OUI)."
      />
      <Flex justify="space-evenly" direction="column" css={{ height: '98%' }}>
        <ReactECharts
          option={{
            xAxis: [
              {
                show: false,
                type: 'category',
                triggerEvent: true,
              },
            ],
            yAxis: [
              {
                type: 'value',
                axisLabel: {
                  formatter: value => (value < 1000000 ? decimalNoFraction.format(value) : `${value / 1000000} mi`),
                },
              },
            ],
            grid: {
              top: '4%',
              right: '0',
              bottom: 0,
              left: '2%',
              containLabel: true,
            },
            legend: { show: false },
            series: chartSeries,
            tooltip: {
              trigger: 'item',
              borderWidth: 0,
              confine: true,
              formatter: value => formatTooltipBar(chartTitle, value, decimalNoFraction),
            },
          }}
          style={{
            width: '100%',
            height: '380px',
          }}
          loading={isLoading}
        />
        <ChartLegend columns={chartData} colors={colors} />
      </Flex>
    </Card>
  );
}
