import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
    "revenue revenue"
    "revenue-pvr revenue-pvr"
    "revenue-gmv revenue-gmv"
    "revenue-productivity revenue-productivity"
    "average-ticket average-ticket"
    "volume-orders volume-orders"
    "first-request first-request"
    "repeat repeat"
    "canceled-orders canceled-orders"
    "invoice-orders invoice-orders"
    "inactivity-level inactivity-level"
    "my-gb-club my-gb-club"
    "donut-chart donut-chart"
    "column-chart column-chart"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
     "revenue revenue"
     "revenue-pvr revenue-pvr"
     "revenue-gmv revenue-gmv"
     "revenue-productivity revenue-productivity"
     "average-ticket average-ticket"
     "volume-orders volume-orders"
     "first-request first-request"
     "repeat repeat"
     "canceled-orders canceled-orders"
     "invoice-orders invoice-orders"
     "inactivity-level inactivity-level"
     "my-gb-club my-gb-club"
     "donut-chart donut-chart"
     "column-chart column-chart"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
      "revenue revenue revenue revenue revenue-pvr revenue-pvr revenue-pvr revenue-pvr revenue-gmv revenue-gmv revenue-gmv revenue-gmv"
      "revenue-productivity revenue-productivity revenue-productivity revenue-productivity average-ticket average-ticket average-ticket average-ticket volume-orders volume-orders volume-orders volume-orders"
      "first-request first-request first-request repeat repeat repeat canceled-orders canceled-orders canceled-orders invoice-orders invoice-orders invoice-orders"
      "inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level"
      "my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club"
      "donut-chart donut-chart donut-chart donut-chart column-chart column-chart column-chart column-chart column-chart column-chart column-chart column-chart"
    `,
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
};
