import { Flex, Text } from '@grupoboticario/flora-react';

export function RelationSectionTitle({ title, subtitle }) {
  return (
    <Flex direction="column">
      <Text size="subTitleDeskMedium" color="$nonInteractiveAuxiliar" css={{ marginTop: '12px' }}>
        {title}
      </Text>
      <Text size="bodySmallStandardMedium" color="$nonInteractiveAuxiliar">
        {subtitle}
      </Text>
    </Flex>
  );
}
