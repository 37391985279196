import { Text } from '@grupoboticario/flora-react';
import { percent } from '@/shared/format/number';

export function PercentageVariation({
  variation,
  achievedGoal,
  isLoading,
}: {
  variation: number;
  achievedGoal: boolean;
  isLoading: boolean;
}) {
  if (isLoading) return null;

  return (
    <Text
      color={achievedGoal ? '$success-standard' : '$error-standard'}
      css={{ minWidth: '10%', marginLeft: '8px', fontSize: '14px' }}
    >
      {variation ? percent.format(variation / 100) : ''}
    </Text>
  );
}
