import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { fetchCycles } from '@/shared/api';
import { useFilters, useFiltersDispatch } from '@/features';

export const useCyclesList = () => {
  const dispatch = useFiltersDispatch();
  const { cycles } = useFilters();

  const { data, isLoading, isFetching } = useQuery({
    staleTime: 1000 * 60 * 60,
    enabled: cycles.length === 0,
    queryKey: ['cycles'],
    queryFn: fetchCycles,
  });

  useEffect(() => {
    if (data && cycles.length === 0) {
      dispatch({
        type: 'SET_CYCLES',
        payload: data.cycles,
      });
    }
  }, [data, dispatch]);

  return { data, isLoading, isFetching };
};
