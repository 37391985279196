import { CardTitle, ReactECharts, ReactEChartsProps, ChartLegend } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { Flex, Text } from '@grupoboticario/flora-react';
import { Select } from 'antd';
import { useSubreason, useSubreasonDispatch } from '../context';
import { darken, lighten, mix } from 'polished';
import { decimalNoFraction, relationTooltip } from '@/shared/format';

function scale(baseColor, scaleSize) {
  if (baseColor === '#011E38') {
    return Array.from({ length: scaleSize }, (_, i) => mix(1 - i / scaleSize, baseColor, 'white'));
  }

  const halfSize = Math.floor(scaleSize / 2);

  const darkenColors = Array.from({ length: halfSize }, (_, i) =>
    mix(1 - i / halfSize, darken(0.2, baseColor), baseColor),
  );

  const lightenColors = Array.from({ length: scaleSize - halfSize - 1 }, (_, i) =>
    mix(1 - (i + 1) / (scaleSize - halfSize), baseColor, lighten(0.2, baseColor)),
  );

  return [...darkenColors, baseColor, ...lightenColors];
}

function makeData(data, color) {
  const orderedData = data?.graph?.sort((a, b) => b.resellers - a.resellers);
  return orderedData?.reverse().map((item, index) => {
    return {
      value: item.resellers,
      name: item.name,
      itemStyle: { color: scale(color, data?.graph?.length).reverse()[index] },
      id: item.code,
    };
  });
}

const { Option } = Select;

export const SubReasonsDonutChart = ({ isLoading, tooltip }) => {
  const { selectedReason, reasons, selectedReasonColor } = useSubreason();

  const dispatch = useSubreasonDispatch();
  const {
    data: subReasonChartData,
    isLoading: isLoadingDonutChart,
    refetch,
  } = useGetDashboardData({
    queryKey: 'relation',
    path: '/relation/details',
    parent: selectedReason,
    enabled: selectedReason !== '',
  });

  const handleSelectChange = reason => {
    dispatch({ type: 'SELECT_REASON', payload: { reason } });
    refetch();
  };

  const option: ReactEChartsProps['option'] = {
    title: {
      textStyle: {
        fontSize: 10,
        color: '#737373',
      },
      subtextStyle: {
        fontSize: 22,
        fontWeight: 'bold',
        color: '#0A0A0A',
      },
      top: '35%',
      left: 'center',
      bottom: 'center',
      right: 'center',
    },
    tooltip: {
      trigger: 'item',
      borderWidth: 0,
      formatter: value => relationTooltip('Total de REs', value.name, value.marker, value.value, value.percent),
    },
    legend: {
      show: false,
    },
  };

  return (
    <Flex gap="$5" direction="column" css={{ width: '100%', height: '100%', gridArea: 'donut-chart' }}>
      <CardTitle title="REs únicas por submotivos de atendimento" tooltip={tooltip} />
      <Flex
        gap="$2"
        direction="column"
        css={{ borderBottom: '1px solid $nonInteractiveAltAuxiliar', paddingBottom: '$6' }}
      >
        <Text size="exceptionsAuxiliarMedium">Selecione um motivo</Text>
        <Select
          disabled={isLoading}
          loading={isLoading}
          placement="bottomLeft"
          optionLabelProp="label"
          onChange={handleSelectChange}
          value={selectedReason}
          style={{ width: '260px' }}
        >
          {reasons?.map(item => {
            return (
              <Option title={item.name} key={item.name} value={item.name} label={item.name}>
                <Text
                  css={{
                    '&::before': {
                      position: 'absolute',
                      backgroundColor: item.color,
                      content: "''",
                      width: '4px',
                      height: '20px',
                      borderRadius: '4px',
                      display: 'inline-block',
                      left: '2px',
                      bottom: '6px',
                    },
                  }}
                >
                  {item.name}
                </Text>
              </Option>
            );
          })}
        </Select>
      </Flex>
      <CardTitle title={`REs únicas por submotivos - ${selectedReason}`} tooltip={tooltip} />
      <Flex direction="column" justify="center" gap="$8" css={{ height: '100%' }}>
        <ReactECharts
          loading={isLoading || isLoadingDonutChart}
          style={{ minWidth: '100%', minHeight: '200px' }}
          option={{
            ...option,
            title: {
              ...(option.title as Record<string, never>),
              text: 'Total de REs',
              subtext: subReasonChartData?.parent.resellers
                ? decimalNoFraction.format(subReasonChartData?.parent.resellers)
                : '',
            },
            series: {
              top: '-30%',
              left: '-20%',
              right: '-20%',
              bottom: '-30%',
              type: 'pie',
              radius: ['46%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 2,
              },
              labelLine: {
                show: false,
              },
              data: makeData(subReasonChartData, selectedReasonColor),
            },
          }}
        />
        <ChartLegend
          columns={subReasonChartData?.graph.reverse()}
          colors={scale(selectedReasonColor, subReasonChartData?.graph?.length)}
        />
      </Flex>
    </Flex>
  );
};
