import { styled } from '@grupoboticario/flora-react';

export const StyledTd = styled('td', {
  padding: '0 $4',
  fontSize: '$bodySmall',
  height: '56px',
  verticalAlign: 'middle',
  left: 0,
  textAlign: 'left',
});
