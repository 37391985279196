import { StructureFilterOption, StructureType } from '../context';

const filterKinds = ['region', 'uf', 'cp', 'cs', 'sector'];

function getRelatedStructures(codes, type: StructureType, structures: StructureFilterOption[]) {
  if (type === 'sector') {
    return structures?.filter(sector => codes.includes(sector.code));
  }
  return structures?.filter(sector =>
    sector.parents.some(parent => parent.type === type && codes.includes(parent.code)),
  );
}

function getCodesInSelectedRegionAndUfs(structures: StructureFilterOption[]) {
  const selectedRegionAndUfCodes = new Set(
    structures?.flatMap(item =>
      item.parents
        .filter(parent => (parent.type === 'uf' || parent.type === 'region') && parent.selected)
        .map(parent => parent.code),
    ),
  );

  const codesInSelectedRegionAndUfs = new Set(
    structures?.flatMap(item => {
      if (item.parents.some(parent => selectedRegionAndUfCodes.has(parent.code))) {
        return [item.code, ...item.parents.map(parent => parent.code)];
      }
      return [];
    }),
  );
  return Array.from(codesInSelectedRegionAndUfs);
}

function getRelatedStructuresTypes(structureType: StructureType) {
  const index = filterKinds.indexOf(structureType);

  const parentsTypes = filterKinds.slice(0, index) ?? [];
  const childrenTypes = filterKinds.slice(index + 1) ?? [];

  return { parentsTypes, childrenTypes };
}

function setSelectedSalesForceModel(state, userModel, hasFvc, hasFvl) {
  return state.salesForce.map(item => ({
    ...item,
    selected:
      userModel === 'SELECTOR'
        ? (hasFvc && hasFvl) || hasFvl || !hasFvc
          ? item.model === 'CAMPO'
          : item.model === 'FVC'
        : item.model === userModel,
    enabled:
      userModel === 'SELECTOR'
        ? hasFvl && hasFvc
          ? true
          : hasFvc
            ? item.model === 'FVC'
            : item.model === 'CAMPO'
        : item.model === userModel,
  }));
}

function updateSelection(structures, relatedSectorCodes, relatedParentCodes, structureType) {
  return structures?.map(item => {
    return {
      ...item,
      selected: relatedSectorCodes.includes(item.code),
      enabled: item.type === structureType ? item.enabled : relatedSectorCodes.includes(item.code),
      parents: item.parents.map(parent => ({
        ...parent,
        selected: relatedParentCodes.includes(parent.code),
        enabled: parent.type === structureType ? parent.enabled : relatedParentCodes.includes(parent.code),
      })),
    };
  });
}

function getSelectedAndEnabledCodes(structures: StructureFilterOption[]) {
  const selectedSectorCodes = structures?.filter(item => item.selected).map(item => item.code);
  const enabledSectorCodes = structures?.filter(item => item.enabled).map(item => item.code);
  const selectedParentsCodes = structures?.flatMap(item =>
    item.parents.filter(parent => parent.selected).map(parent => parent.code),
  );

  const enabledParentsCodes = structures?.flatMap(item =>
    item.parents.filter(parent => parent.enabled).map(parent => parent.code),
  );

  return {
    enabledCodesInLastRequest: [enabledParentsCodes, enabledSectorCodes].flat(),
    selectedCodesInLastRequest: [selectedParentsCodes, selectedSectorCodes].flat(),
  };
}

function setInitialFilters(structures, selected) {
  return structures?.map(structure => ({
    ...structure,
    selected,
    enabled: selected,
    parents: structure?.parents.map(parent => ({
      ...parent,
      selected,
      enabled: selected,
    })),
  }));
}

function setApplyedFilters(state, salesForceModel) {
  filterKinds.forEach(filterKind => {
    const allOptionsSelectedAndEnabled =
      filterKind === 'sector'
        ? state.structures[salesForceModel].every(item => item.selected && item.enabled)
        : state.structures[salesForceModel]
            .flatMap(item => item.parents.filter(parent => parent.type === filterKind))
            .every(parent => parent.selected && parent.enabled);

    if (!state.applyedFilters.includes(filterKind)) {
      state.applyedFilters = [...state.applyedFilters, filterKind];
    }

    if (allOptionsSelectedAndEnabled) {
      state.applyedFilters = state.applyedFilters.filter(item => item !== filterKind);
    }
  });
}

export {
  getRelatedStructures,
  getCodesInSelectedRegionAndUfs,
  getRelatedStructuresTypes,
  updateSelection,
  setInitialFilters,
  setSelectedSalesForceModel,
  getSelectedAndEnabledCodes,
  setApplyedFilters,
};
