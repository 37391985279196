import { SkeletonItem } from '@/shared/components';
import { Button, Card, Flex, Text } from '@grupoboticario/flora-react';
import { MagnifyingGlassIcon, SquareArrowRightUpIcon } from '@grupoboticario/flora-react-icons';
import { useNavigate } from 'react-router-dom';

const skeletonData = Array(3).fill({ name: '', description: '', calculation: '', accessLocation: '' });

function foundItemsWithSearchTerm(calculationLogData, searchTerm) {
  return calculationLogData?.filter(item => {
    return ['name', 'description', 'calculation'].some(key =>
      item[key].toString().toLowerCase().includes(searchTerm.toLowerCase()),
    );
  });
}

export function CalculationLogList({ calculationLogData, isLoading, searchTerm }) {
  const navigate = useNavigate();

  const displayedData = isLoading ? skeletonData : foundItemsWithSearchTerm(calculationLogData, searchTerm);

  function highlightText(text, searchTerm) {
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);

    return (
      <Text size="bodySmallStandardRegular" color="$nonInteractivePredominant">
        {parts.map((part, index) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <Text key={index} css={{ backgroundColor: '$nonPrimaryButtonActive' }} size="bodySmallStandardRegular">
              {part}
            </Text>
          ) : (
            part
          ),
        )}
      </Text>
    );
  }

  return (
    <Flex css={{ width: '100%' }} gap="$4" direction="column">
      {displayedData?.map(({ name, description, calculation, accessLocation }, index) => (
        <Card key={index} data-testid="calculation-log-card" css={{ padding: '$8' }}>
          <Flex css={{ marginBottom: '$4' }}>
            <Flex direction="column" css={{ width: '28%' }}>
              <Text weight="heavy" size="exceptionsAuxiliarBold" color="$nonInteractiveEmphasis">
                Nome
              </Text>
              {isLoading ? (
                <SkeletonItem css={{ height: '20px', width: '90%' }}>&nbsp;</SkeletonItem>
              ) : (
                <Text css={{ width: '90%' }} size="bodySmallStandardRegular" color="$nonInteractivePredominant">
                  {highlightText(name, searchTerm)}
                </Text>
              )}
            </Flex>
            <Flex direction="column" css={{ width: '72%' }}>
              <Text weight="heavy" size="exceptionsAuxiliarBold" color="$nonInteractiveEmphasis">
                Descrição
              </Text>
              {isLoading ? (
                <>
                  <SkeletonItem css={{ height: '20px', marginBottom: '2px' }}>&nbsp;</SkeletonItem>
                  <SkeletonItem css={{ height: '20px', marginBottom: '2px' }}>&nbsp;</SkeletonItem>
                  <SkeletonItem css={{ height: '20px', marginBottom: '2px', width: '40%' }}>&nbsp;</SkeletonItem>
                </>
              ) : (
                <Text size="bodySmallStandardRegular" color="$nonInteractivePredominant">
                  {highlightText(description, searchTerm)}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex>
            <Flex direction="column" css={{ width: '28%' }}>
              <Text weight="heavy" size="exceptionsAuxiliarBold" color="$nonInteractiveEmphasis">
                Local de acesso
              </Text>
              {isLoading ? (
                <SkeletonItem css={{ height: '20px', width: '90%' }}>&nbsp;</SkeletonItem>
              ) : (
                <Button
                  css={{ width: '148px' }}
                  size="small"
                  hierarchy="tertiary"
                  icon={<SquareArrowRightUpIcon />}
                  onClick={() => navigate(accessLocation)}
                >
                  Visualizar indicador
                </Button>
              )}
            </Flex>
            <Flex direction="column" css={{ width: '72%' }}>
              <Text weight="heavy" size="exceptionsAuxiliarBold" color="$nonInteractiveEmphasis">
                Como o cálculo é feito?
              </Text>
              {isLoading ? (
                <>
                  <SkeletonItem css={{ height: '20px', marginBottom: '2px' }}>&nbsp;</SkeletonItem>
                  <SkeletonItem css={{ height: '20px', marginBottom: '2px' }}>&nbsp;</SkeletonItem>
                  <SkeletonItem css={{ height: '20px', marginBottom: '2px', width: '40%' }}>&nbsp;</SkeletonItem>
                </>
              ) : (
                <Text size="bodySmallStandardRegular" color="$nonInteractivePredominant">
                  {highlightText(calculation, searchTerm)}
                </Text>
              )}
            </Flex>
          </Flex>
        </Card>
      ))}
      {displayedData?.length === 0 && (
        <Flex css={{ marginTop: '80px' }} direction="column" align="center" justify="center" gap="$4">
          <MagnifyingGlassIcon color="$nonInteractiveAuxiliar" size="34px" />
          <Text size="subTitleDeskMedium" color="$nonInteractiveAuxiliar">
            Não há resultados para sua pesquisa
          </Text>
          <Text size="bodyLargeStandardMedium" color="$nonInteractiveAuxiliar">
            Altere o texto e tente novamente
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
