import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
    "total-base total-base"
    "available available"
    "settled settled"
    "overdue overdue"
    "blocked blocked"
    "active-base active-base"
    "multi-brand-base multi-brand-base"
    "net-additions net-additions"
    "loss loss"
    "churn churn"
    "inactivity-level inactivity-level"
    "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
     "total-base total-base total-base available available available"
     "settled settled overdue overdue blocked blocked"
     "active-base active-base active-base multi-brand-base multi-brand-base multi-brand-base"
     "net-additions net-additions net-additions loss loss loss"
     "churn churn churn churn churn churn"
     "inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level"
     "my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
      "total-base total-base total-base available available available settled settled overdue overdue blocked blocked"
      "active-base active-base active-base active-base active-base active-base multi-brand-base multi-brand-base multi-brand-base multi-brand-base multi-brand-base multi-brand-base"
      "net-additions net-additions net-additions net-additions loss loss loss loss churn churn churn churn"
      "inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
};

export const gridSettingsDigitalBase: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
     "total-base-actives total-base-actives"
     "digital-channel-actives digital-channel-actives"
     "digital-channel-penetration digital-channel-penetration"
     "inactivity-level inactivity-level"
     "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
     "total-base-actives total-base-actives"
     "digital-channel-actives digital-channel-actives"
     "digital-channel-penetration digital-channel-penetration"
     "inactivity-level inactivity-level"
     "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
      "total-base-actives total-base-actives digital-channel-actives digital-channel-actives digital-channel-penetration digital-channel-penetration"
      "inactivity-level inactivity-level inactivity-level my-gb-club my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};
