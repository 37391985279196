import { FilterDrawer } from '@/features';
import { RevenueDashboard } from '@/features/RevenueDashboard/RevenueDashboard';
import { PageTitle } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';

export function Revenue() {
  return (
    <>
      <Flex align="center" justify="space-between">
        <PageTitle>Receita</PageTitle>
        <FilterDrawer />
      </Flex>
      <RevenueDashboard />
    </>
  );
}
