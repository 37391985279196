import { Card, CardTitle, ChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { decimalNoFraction, formatTooltipBar, brandsLabelMap, setOptions } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';

interface ColumnChartProps {
  gridArea: string;
  data: { code: string; value: number }[];
  isLoading: boolean;
}
const customOrderArray = ['oui', 'eud', 'boti', 'qdb'];
const colors = ['#971B2F', '#613A53', '#00A775', '#d6d1ca'];

const chartTitle = 'Receita por marca';

export function ColumnChart({ gridArea, data, isLoading }: ColumnChartProps) {
  const isMobile = window.innerWidth < 680;

  const columnsCustomOrder = data?.sort((a, b) => customOrderArray.indexOf(a.code) - customOrderArray.indexOf(b.code));

  const chartData = columnsCustomOrder?.map(item => ({
    name: brandsLabelMap[item.code] || item.code,
    value: item.value,
    ...item,
  }));

  const chartSeries: ReactEChartsProps['option']['series'] = chartData?.map(column => ({
    type: 'bar',
    seriesLayoutBy: 'row',
    name: column.code,
    data: [{ code: column.code, value: column.value, name: brandsLabelMap[column.code] || column.code }],
    label: {
      show: !isMobile,
      position: 'inside',
      formatter: ({ value }) => decimalNoFraction.format(Number(value)),
      fontSize: 14,
      textBorderColor: 'inherit',
      textBorderWidth: 3,
    },
  }));

  return (
    <Card css={{ gridArea: gridArea, height: '100%' }}>
      <CardTitle title={chartTitle} tooltip="Receita por marca" />
      <Flex justify="space-evenly" direction="column" css={{ height: '98%' }}>
        <ReactECharts
          option={{
            ...setOptions({
              colors,
              formatter: value => (value < 1000000 ? decimalNoFraction.format(value) : `${value / 1000000} mi`),
            }),
            series: chartSeries,
            tooltip: {
              trigger: 'item',
              borderWidth: 0,
              confine: true,
              formatter: value => formatTooltipBar(chartTitle, value, decimalNoFraction),
            },
          }}
          style={{
            width: '100%',
            height: '380px',
          }}
          loading={isLoading}
        />
        <ChartLegend columns={chartData} colors={colors} />
      </Flex>
    </Card>
  );
}
