import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Navigate } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@/shared/api/queryClient';
import { styles } from '@/shared/styles';
import { PageLayout } from './shared/layout';
import { Overview, BaseProfile, About, Activity, CalculationLog, Revenue, Relation } from '@/pages';
import { FiltersProvider } from './features';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PageLayout />}>
        <Route index path="/" element={<Navigate to="/visao-geral" replace />} />
        <Route path="/visao-geral" element={<Overview />} />
        <Route path="/perfil-de-base" element={<BaseProfile />} />
        <Route path="/atividade" element={<Activity />} />
        <Route path="/receita" element={<Revenue />} />
        <Route index path="/atendimento" element={<Navigate to="/relacionamento" replace />} />
        <Route path="/relacionamento" element={<Relation />} />
      </Route>
      <Route>
        <Route path="/sobre" element={<About />} />
        <Route path="/memoria-de-calculo" element={<CalculationLog />} />
      </Route>
    </>,
  ),
  { basename: '/dashboards' },
);

export function App() {
  styles();

  return (
    <QueryClientProvider client={queryClient}>
      <FiltersProvider>
        <RouterProvider router={router} />
      </FiltersProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
