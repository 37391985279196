import { DashboardGrid, Table } from '@/shared/components';
import { gridSettings } from './grid-settings';
import { decimal, currency, percentNoFraction, decimalNoFraction, currencyNoFraction } from '@/shared/format';
import { IndicatorCardRenevue, ChartDonnut, ColumnChart } from './components';
import { useGetDashboardData } from '@/shared/hooks';

const channelTitle = 'Receitas por Meios de Captação';
const brandTitle = 'Receitas por Marcas';

export function RevenueDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'revenue', path: '/revenue/dashboard' });

  return (
    <DashboardGrid css={gridSettings}>
      <IndicatorCardRenevue
        title="Receita RBV"
        tooltip="Receita RBV"
        gridArea="revenue"
        value={data?.revenueRbv?.realized !== undefined ? currency.format(data.revenueRbv.realized) : '-'}
        goal={data?.revenueRbv?.goal !== undefined ? currency.format(data.revenueRbv.goal) : '-'}
        achievement={
          data?.revenueRbv?.achievement !== undefined
            ? percentNoFraction.format(data?.revenueRbv?.achievement / 100)
            : '-'
        }
        isLoading={isLoading}
        showGoalLabel
      />
      <IndicatorCardRenevue
        title="Receita PVR"
        tooltip="Receita PVR"
        gridArea="revenue-pvr"
        value={data?.revenuePvr?.realized !== undefined ? currency.format(data.revenuePvr.realized) : '-'}
        isLoading={isLoading}
      />
      <IndicatorCardRenevue
        title="Receita GMV"
        tooltip="Receita GMV"
        gridArea="revenue-gmv"
        value={data?.revenueGmv?.realized !== undefined ? currency.format(data.revenueGmv.realized) : '-'}
        isLoading={isLoading}
      />
      <IndicatorCardRenevue
        title="Produtividade"
        tooltip="Produtividade"
        gridArea="revenue-productivity"
        value={data?.productivity?.realized !== undefined ? currency.format(data.productivity.realized) : '-'}
        goal={data?.productivity?.goal !== undefined ? currency.format(data.productivity.goal) : '-'}
        auxiliarText={
          data?.productivity?.actives !== undefined
            ? `referente à ${decimal.format(data.productivity.actives)} REs Ativos`
            : '-'
        }
        achievement={
          data?.productivity?.achievement !== undefined
            ? percentNoFraction.format(data.productivity.achievement / 100)
            : '-'
        }
        isLoading={isLoading}
        showGoalLabel
      />
      <IndicatorCardRenevue
        title="Ticket Médio"
        tooltip="Ticket Médio"
        gridArea="average-ticket"
        value={data?.averageTicket?.average !== undefined ? currency.format(data.averageTicket.average) : '-'}
        auxiliarText={
          data?.averageTicket?.totalOrders !== undefined
            ? `referente à ${decimalNoFraction.format(data.averageTicket.average)} pedidos`
            : '-'
        }
        isLoading={isLoading}
      />
      <IndicatorCardRenevue
        title="Volume de pedidos"
        tooltip="Volume de pedidos"
        gridArea="volume-orders"
        value={data?.orderVolume?.value !== undefined ? decimal.format(data.orderVolume.value) : '-'}
        goal=""
        isLoading={isLoading}
      />
      <IndicatorCardRenevue
        title="Primeiro pedido"
        tooltip="Primeiro pedido"
        gridArea="first-request"
        value={data?.firstOrders?.value !== undefined ? decimal.format(data.firstOrders.value) : '-'}
        goal={
          data?.firstOrders?.percentage !== undefined
            ? percentNoFraction.format(data.firstOrders.percentage / 100)
            : '-'
        }
        isLoading={isLoading}
      />
      <IndicatorCardRenevue
        title="Repique"
        tooltip="Repique"
        gridArea="repeat"
        value={data?.subsequentOrders?.value !== undefined ? decimal.format(data.subsequentOrders.value) : '-'}
        goal={
          data?.subsequentOrders?.percentage !== undefined
            ? percentNoFraction.format(data.subsequentOrders.percentage / 100)
            : '-'
        }
        isLoading={isLoading}
      />
      <IndicatorCardRenevue
        title="Pedidos cancelados"
        tooltip="Pedidos cancelados"
        gridArea="canceled-orders"
        value={data?.canceledOrders?.value !== undefined ? decimal.format(data.canceledOrders.value) : '-'}
        goal={
          data?.canceledOrders?.percentage !== undefined
            ? percentNoFraction.format(data.canceledOrders.percentage / 100)
            : '-'
        }
        isLoading={isLoading}
      />
      <IndicatorCardRenevue
        title="Pedidos a faturar"
        tooltip="Pedidos a faturar"
        gridArea="invoice-orders"
        value={data?.invoicingOrders?.value !== undefined ? decimal.format(data.invoicingOrders.value) : '-'}
        goal={
          data?.invoicingOrders?.percentage !== undefined
            ? percentNoFraction.format(data.invoicingOrders.percentage / 100)
            : '-'
        }
        isLoading={isLoading}
      />
      <Table
        title="Nível de inatividade"
        tooltipText="Receita por nível de inatividade"
        isInactivityTable
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        format={currencyNoFraction.format}
        isLoading={isLoading}
        data={data?.revenuePerInactivityLevel}
      />
      <Table
        title="Meu clube GB"
        tooltipText="Receita por faixa no Meu Clube GB"
        format={currencyNoFraction.format}
        channelTitle={channelTitle}
        brandTitle={brandTitle}
        isLoading={isLoading}
        data={data?.revenuePerMeuClubeGBLevel}
      />
      <ChartDonnut gridArea="donut-chart" data={data?.revenueTopCategories} isLoading={isLoading} />
      <ColumnChart gridArea="column-chart" data={data?.revenuePerBrand} isLoading={isLoading} />
    </DashboardGrid>
  );
}
