import { GtmTracker } from '@vdi/event-tracker';

const { track } = GtmTracker;

type filterKind =
  | 'ciclo'
  | 'forca_de_vendas'
  | 'franqueado'
  | 'espaco_revendedor'
  | 'setor'
  | 'limpar_filtro'
  | 'região'
  | 'uf';
type tabKind = 'perfil_de_base' | 'atendimento' | 'atividade';
type representationByTabName = 'meios_de_captacao' | 'marcas';
type tabulationKind = string;

export function useEventTracker() {
  return {
    clickedOnFilter: ({ filterName }: { filterName: filterKind }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_filtros',
          cd_interaction_detail: `click:${filterName}`,
        },
      });
    },
    clickedOnTab: ({ tabName }: { tabName: tabKind }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_tabs',
          cd_interaction_detail: `click:${tabName}`,
        },
      });
    },
    clickedOnRepresentationTab: ({ tabName }: { tabName: representationByTabName }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_atividade',
          cd_interaction_detail: `click:${tabName}`,
        },
      });
    },
    clickedOnTabulationChart: ({ tabulationKind }: { tabulationKind: tabulationKind }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_atendimento',
          cd_interaction_detail: `click:${tabulationKind}`,
        },
      });
    },
    clickedOnTabulationBreadcrumb: ({ tabulationKind }: { tabulationKind: tabulationKind }) => {
      track({
        event: 'analytics-event',
        event_parameters: {
          event_name: 'interaction_breadcrumb',
          cd_interaction_detail: `click:${tabulationKind}`,
        },
      });
    },
  };
}
