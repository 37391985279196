import { DEFAULT_LOCALE } from '@/shared/locale';

export const percent = new Intl.NumberFormat(DEFAULT_LOCALE, {
  style: 'percent',
  maximumFractionDigits: 2,
});

export const percentNoFraction = new Intl.NumberFormat(DEFAULT_LOCALE, {
  style: 'percent',
  maximumFractionDigits: 0,
});
