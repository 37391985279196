export type Indicator = 'totalBase' | 'actives' | 'revenue' | 'productivity';
export type ChartKind = 'achievement' | 'detail' | 'detail_currency' | 'detail_currency_million';

interface CardTextInfo {
  achievement: {
    title: string;
    tooltip: string;
  };
  detail?: {
    title: string;
    tooltip: string;
  };
  detail_currency_million?: {
    title: string;
    tooltip: string;
  };
  detail_currency?: {
    title: string;
    tooltip: string;
  };
}

export const cardTextInfo: {
  [key in Indicator]: CardTextInfo;
} = {
  totalBase: {
    achievement: {
      title: 'Atingimento de Meta de Base ativa - FV, por ciclo.',
      tooltip:
        'Percentual do atingimento da meta de base ativa, gerenciados pela Força de Vendas, nos ciclos selecionados.',
    },
    detail: {
      title: ' Resultados versus Meta de Base ativa - FV, por ciclo.',
      tooltip:
        'Comparativo entre a meta e a quantidade da base ativa, gerenciados pela Força de Vendas, nos ciclos selecionados.',
    },
  },
  actives: {
    achievement: {
      title: 'Atingimento de Meta de Revendedores Ativos - FV, por ciclo.',
      tooltip:
        'Percentual do atingimento da meta de revendedores ativos, gerenciados pela Força de Vendas, nos ciclos selecionados.',
    },
    detail: {
      title: ' Resultados versus Meta de Revendedores Ativos - FV, por ciclo.',
      tooltip:
        'Comparativo entre a meta e a quantidade de revendedores ativos, gerenciados pela Força de Vendas, nos ciclos selecionados.',
    },
  },
  revenue: {
    achievement: {
      title: 'Atingimento de Meta de Receita - FV, por ciclo.',
      tooltip:
        'Percentual do atingimento da meta de receita gerada pelos revendores, gerenciados pela Força de Vendas, por ciclo selecionado.',
    },
    detail_currency_million: {
      title: 'Resultados versus Meta de  Receita - FV, por ciclo.',
      tooltip:
        'Comparativo entre a meta e a receita gerada pelo revendedores, gerenciados pela Força de Vendas, nos ciclos selecionados.',
    },
  },
  productivity: {
    achievement: {
      title: 'Atingimento de Meta de Produtividade - FV, por ciclo.',
      tooltip:
        'Percentual do atingimento da meta de produtividade gerada pelos revendedores, gerenciados pela Força de Vendas, ativos por ciclo selecionado.',
    },
    detail_currency: {
      title: 'Resultados versus Meta de Produtividade - FV, por ciclo.',
      tooltip:
        'Comparativo entre a meta e a produtividade gerada pelos revendedores, gerenciados pela Força de Vendas, ativos por ciclo selecionado.',
    },
  },
};
