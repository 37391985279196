import { Text } from '@grupoboticario/flora-react';

export function DisabledInput({ text }) {
  return (
    <Text
      css={{
        maxWidth: '100%',
        height: '48px',
        borderRadius: '6px',
        boxShadow: `inset 0 0 0 1px rgba(0,0,0, .48)`,
        padding: '$3',
        backgroundColor: '#f1f1f1',
        fontSize: '14px',
        whiteSpace: 'nowrap',
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        alignItems: 'center',
      }}
    >
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{text}</span>
    </Text>
  );
}
