import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
        "total-base"
        "revenue"
        "active"
        "activity"
        "productivity"
        "block"
        "active-er"
        "active-mm"
        "active-resellers-chart"
    `,
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
        "total-base revenue"
        "active activity"
        "productivity block"
        "active-er active-mm"
        "active-resellers-chart active-resellers-chart"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
        "total-base revenue active"
        "activity productivity block"
        "active-mm active-resellers-chart active-resellers-chart"
        "active-er active-resellers-chart active-resellers-chart"
    `,
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
};
