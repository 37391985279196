import { authService } from '@vdi/auth-service';

export async function fetchDashboard({ cycle, sector, model, path, signal, parent }) {
  const { token } = await authService.getTokenOrRedirect();
  const request = await fetch(`${process.env.API_BASE_URL}${path}`, {
    body: JSON.stringify({ cycle, sector, model, parent }),
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json',
    },
    signal,
  });

  if (!request.ok) {
    throw new Error(`Network response was not ok`);
  }

  return request.json();
}
