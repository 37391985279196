import { Card, CardTitle, ReactECharts, ToggleButton } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';
import { ChartKind, Indicator, TimeSeriesOverviewCyclesData, cardTextInfo, useTimeSeriesChart } from '@/features';

interface TimeSeriesChartProps {
  indicator: Indicator;
  selectedCycles: string[];
  cyclesData: TimeSeriesOverviewCyclesData[];
  chartKinds?: ChartKind[];
  isLoading: boolean;
}

export function TimeSeriesChart({
  indicator,
  selectedCycles,
  cyclesData,
  chartKinds,
  isLoading,
}: TimeSeriesChartProps) {
  const { options, setDisplayedChart, displayedChart } = useTimeSeriesChart({
    cyclesData,
    indicator,
    selectedCycles,
    chartKinds,
  });

  return (
    <Card>
      <Flex direction="column" gap="$4">
        <Flex
          css={{
            flexDirection: 'column',
            '@tablet': { flexDirection: 'row', alignItems: 'center' },
          }}
          justify="space-between"
          gap="$1"
        >
          <CardTitle
            title={cardTextInfo[indicator][displayedChart]?.title}
            tooltip={cardTextInfo[indicator][displayedChart]?.tooltip}
          />
          {chartKinds?.length > 1 && (
            <ToggleButton
              leftText="Atingimento"
              onLeftButtonClick={() => setDisplayedChart(chartKinds[0])}
              rightText="Detalhe"
              onRightButtonClick={() => setDisplayedChart(chartKinds[1])}
            />
          )}
        </Flex>
        <ReactECharts loading={isLoading} option={options} style={{ minWidth: '100%', height: '340px' }} />
      </Flex>
    </Card>
  );
}
