import { ActiveResellersChart, DashboardGrid, IconCardIndicator, IndicatorCard, Table } from '@/shared/components';
import { useGetDashboardData } from '@/shared/hooks';
import { gridSettings } from './grid-settings';
import { decimal, decimalNoFraction, percent } from '@/shared/format';
import { ThreePeopleIcon } from '@grupoboticario/flora-react-icons';

export function BaseProfileDashboard() {
  const { data, isLoading } = useGetDashboardData({ queryKey: 'base', path: '/base/dashboard' });

  return (
    <DashboardGrid css={gridSettings}>
      <IconCardIndicator
        title="Base ativa - FV"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que realizaram pelo menos um pedido nos 6 ciclos anteriores ao ciclo selecionado (de A0 a I6), excluindo Inícios e Reinícios."
        gridArea="total-base"
        icon={<ThreePeopleIcon />}
        data={data?.totalBase}
        format={decimal.format}
        isLoading={isLoading}
      />
      <IndicatorCard
        title="Base disponível"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que não possuem bloqueio cadastral."
        gridArea="available"
        value={data?.available?.total ? decimal.format(data?.available?.total) : '-'}
        goal={data?.available?.percentage ? `${percent.format(data?.available?.percentage / 100)} do total` : '-'}
        isLoading={isLoading}
      />
      <IndicatorCard
        title="Base indisponível"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, que possuem bloqueio cadastral."
        gridArea="blocked"
        value={data?.blocked?.total ? decimal.format(data?.blocked?.total) : '-'}
        goal={data?.blocked?.percentage / 100 ? `${percent.format(data?.blocked?.percentage / 100)} do total` : '-'}
        isLoading={isLoading}
      />
      <IndicatorCard
        title="Adimplente"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, sem pagamentos em atraso."
        gridArea="perishable"
        value={data?.settled?.total ? decimal.format(data?.settled?.total) : '-'}
        goal={data?.settled?.percentage / 100 ? `${percent.format(data?.settled?.percentage / 100)} do total` : '-'}
        isLoading={isLoading}
      />
      <IndicatorCard
        title="Inadimplente"
        tooltip="Quantidade de revendedores, gerenciados pela Força de Vendas, com pagamentos em atraso."
        gridArea="defaulter"
        value={data?.overdue?.total ? decimal.format(data?.overdue?.total) : '-'}
        goal={data?.overdue?.percentage / 100 ? `${percent.format(data?.overdue?.percentage / 100)} do total` : '-'}
        isLoading={isLoading}
      />
      <ActiveResellersChart isLoading={isLoading} chart={data?.actives} />
      <Table
        title="Nível de inatividade"
        tooltipText="Comparativo da base ativa pela quantidade de revendedores ativos, gerenciados pela Força de Vendas, pelo nível de inatividade no ciclo selecionado"
        isInactivityTable
        format={decimalNoFraction.format}
        isLoading={isLoading}
        data={data?.basePerInactivityLevel}
      />
      <Table
        title="Meu clube GB"
        tooltipText="Comparativo da base ativa pela quantidade de revendedores ativos, gerenciados pela Força de Vendas, por faixa no Meu Clube GB no ciclo selecionado."
        format={decimalNoFraction.format}
        isLoading={isLoading}
        data={data?.basePerMeuClubeGBLevel}
      />
    </DashboardGrid>
  );
}
