import { clsx } from 'clsx';
import { Flex, Hidden, Text } from '@grupoboticario/flora-react';
import { Card, CardTitle, PercentageVariation } from '@/shared/components';
import { percent } from '@/shared/format';

interface IndicatorData {
  value: string;
  goal: string;
  goalVariation: string;
  format: (value: number) => string;
}
interface IconCardIndicatorProps {
  isLoading: boolean;
  icon: JSX.Element;
  title: string;
  gridArea?: string;
  tooltip?: string;
  data: IndicatorData;
  format: (value: number) => string;
}

export function IconCardIndicator({ icon, title, tooltip, data, isLoading, gridArea, format }: IconCardIndicatorProps) {
  function iconCardData(data, format) {
    const isPercent = format === percent.format;

    if (isPercent) {
      return {
        value: data?.realized ? format(data?.realized / 100) : '-',
        goal: data?.goal ? format(data?.goal / 100) : '-',
        achievement: data?.achievement,
      };
    }

    return {
      value: data?.realized ? format(data?.realized) : '-',
      goal: data?.goal ? format(data?.goal) : '-',
      achievement: data?.achievement,
    };
  }

  const { value, goal, achievement: goalVariation } = iconCardData(data, format);

  const achievedGoal = Number(goalVariation) >= 100;

  return (
    <Card css={{ gridArea, flexGrow: '1', minWidth: '240px' }}>
      <Flex direction="row" align="center" css={{ height: '100%' }}>
        <Hidden only={['mobile', 'tablet']}>
          {{
            ...icon,
            props: {
              size: 36,
              css: {
                backgroundColor: '$backgroundTertiary',
                borderRadius: '8px',
                color: '$nonInteractiveEmphasis',
                padding: '16px',
              },
            },
          }}
        </Hidden>
        <Flex
          direction="column"
          justify="center"
          css={{ height: '100%', flex: '1 1 auto', '@desktop': { marginLeft: '$4' } }}
        >
          <CardTitle title={title} tooltip={tooltip} />
          <Text
            className={clsx(isLoading && 'loading-placeholder')}
            size="subTitleDeskBold"
            weight="heavy"
            css={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </Text>
          <Text size="exceptionsAuxiliarMedium" color="$nonInteractiveAuxiliar" css={{ textTransform: 'uppercase' }}>
            Meta
          </Text>
          <Flex align="center">
            <Text
              className={clsx(isLoading && 'loading-placeholder loading-placeholder-subtitle')}
              color="$nonInteractivePredominant"
              size="bodyLargeStandardMedium"
              css={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {goal}
            </Text>
            <PercentageVariation isLoading={isLoading} variation={Number(goalVariation)} achievedGoal={achievedGoal} />
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
