import { Card, CardTitle, DonutChartLegend, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { formatTooltipBar, percentNoFraction } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';

interface ChartDonnutProps {
  gridArea: string;
  data: { code: string; percentage: number }[];
  isLoading: boolean;
}

const capitalizeWords = (str: string) => {
  return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

const generateChartColor = (index: number, code: string) => {
  const colors = ['#4A5DA3', '#8495D3', '#C85035', '#7DBE78', '#EB9D03'];
  return code.toLowerCase() === 'outros' ? '#596D79' : colors[index % colors.length];
};

const chartTitle = 'Receita GMV - Top 5 Categoria';

export function ChartDonnut({ gridArea, data, isLoading }: ChartDonnutProps) {
  const chartData = data?.map((item, index) => ({
    value: Math.round(item.percentage),
    name: capitalizeWords(item.code),
    color: generateChartColor(index, item.code),
  }));

  const option: ReactEChartsProps['option'] = {
    legend: {
      show: false,
    },
    series: {
      top: '-10%',
      left: '0',
      right: '0',
      bottom: '-10%',
      type: 'pie',
      radius: ['60%', '84%'],
      label: {
        show: false,
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: '#fff',
      },
      data: chartData?.map(item => ({
        value: item.value,
        name: item.name,
        itemStyle: { color: item.color },
      })),
    },
    tooltip: {
      trigger: 'item',
      borderWidth: 0,
      confine: true,
      formatter: value => formatTooltipBar(chartTitle, value, percentNoFraction),
    },
  };

  return (
    <Card css={{ gridArea: gridArea }}>
      <CardTitle title={chartTitle} tooltip="Receita GMV originada pelas 5 categorias mais representativas." />
      <Flex justify="center" css={{ padding: '$2 0' }}>
        <ReactECharts loading={isLoading} option={option} style={{ width: '200px', height: '200px' }} />
      </Flex>
      <DonutChartLegend chartData={chartData} />
    </Card>
  );
}
