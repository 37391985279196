import { meuClubeGbLevelNameMap, percentNoFraction } from '@/shared/format';
import { Flex } from '@grupoboticario/flora-react';
import { inactivityLevelLabelMap, StyledPercentage } from '@/shared/components';

export function TableCellText({
  displayValue,
  percentage,
  format,
  textPercentage,
  isActivityDashboard,
  isChannelOrBrand,
  isBase,
}) {
  return (
    <Flex css={{ textAlign: 'right' }} direction="column">
      {displayValue === undefined ? '-' : format(displayValue)}
      {textPercentage !== 'Total' && (
        <StyledPercentage>
          {percentage === undefined || (isActivityDashboard && isChannelOrBrand)
            ? ''
            : `${percentNoFraction.format(percentage / 100)} ${isBase ? 'do total' : `dos ${meuClubeGbLevelNameMap[textPercentage] || inactivityLevelLabelMap[textPercentage] || textPercentage}`}`}
        </StyledPercentage>
      )}
    </Flex>
  );
}
