import { percentNoFraction } from '@/shared/format';
import { Flex, Text } from '@grupoboticario/flora-react';

export function DonutChartLegend({ chartData }) {
  return (
    <Flex css={{ flexDirection: 'column' }}>
      {chartData?.map((item, index) => {
        return (
          <Flex
            key={item.name}
            css={{
              height: '38px',
              alignItems: 'center',
              gap: '$4',
              borderBottom: index < chartData?.length - 1 ? '1px solid $nonInteractiveOutline' : 'none',
              borderTop: index === 0 ? '1px solid #ccc' : 'none',
              fontSize: '14px',
            }}
          >
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: item.color,
                borderRadius: '100%',
              }}
            />
            <Text css={{ width: '$8' }}>{percentNoFraction.format(item.value / 100)}</Text>
            <Text>{item.name}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
}
