import { DashboardGridProps } from '@/shared/components/DashboardGrid/DashboardGrid';

export const gridSettings: DashboardGridProps['css'] = {
  '@mobile': {
    gridTemplateAreas: `
    "total-base total-base"
    "active active"
    "activity activity"
    "active-mm active-mm"
    "active-er active-er"
    "inactivity-level inactivity-level"
    "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@tablet': {
    gridTemplateAreas: `
     "total-base total-base"
     "active activity"
     "active-mm active-er"
     "inactivity-level inactivity-level"
     "my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  '@desktop': {
    gridTemplateAreas: `
      "total-base total-base active active activity activity"
      "active-mm active-mm active-mm active-er active-er active-er"
      "inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level inactivity-level"
      "my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club my-gb-club"
    `,
    gridTemplateColumns: 'repeat(6, 1fr)',
  },
};
