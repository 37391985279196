import { decimal } from '@/shared/format';
import { ChartKind, Indicator } from './constants';
import { currencyNoFraction } from '@/shared/format/number/currency';
import { TimeSeriesOverviewCyclesData } from './types';

export interface ChartData {
  value: (string | number)[];
}
interface CyclesDataParams {
  total: ChartData[];
  actual: ChartData[];
  previous: ChartData[];
}

interface GenerateCycleDataParams {
  cyclesData: TimeSeriesOverviewCyclesData[];
  indicator: Indicator;
  latestCycle: string;
  results: string[];
}

interface SeriesData {
  achievementSeriesData?: CyclesDataParams;
  goalSeriesData?: CyclesDataParams;
  realizedSeriesData?: CyclesDataParams;
  invoicingSeriesData?: CyclesDataParams;
}

export function generateCycleData({ cyclesData, indicator, latestCycle, results }: GenerateCycleDataParams) {
  const cyclesDataAscendingOrder = [...cyclesData].sort((a, b) => Number(a.cycle) - Number(b.cycle));

  const seriesDataObject: SeriesData = {};

  results.forEach(result => {
    const seriesData = cyclesDataAscendingOrder.map(cycle => {
      return { value: [`${cycle.cycle}`, cycle[indicator][result]] };
    });

    seriesDataObject[`${result}SeriesData`] = {
      total: seriesData,
      actual: seriesData.filter(item => item.value[0] === latestCycle),
      previous: seriesData.filter(item => item.value[0] !== latestCycle),
    };
  });

  return seriesDataObject;
}

export function formatTimeSeriesValues(value, chartKind) {
  const numberConversion = Number(value);
  if (!value) {
    return '-';
  }
  if (chartKind === 'achievement') {
    return `${numberConversion.toFixed(0)}%`;
  }

  if (chartKind === 'detail_currency') {
    return currencyNoFraction.format(value);
  }

  if (chartKind === 'detail_currency_million') {
    if (value < 1000000) {
      return `R$ ${(numberConversion / 1000).toFixed(0)} mil`;
    }
    return `R$ ${(numberConversion / 1000000).toFixed(0)} mi`;
  }

  if (chartKind === 'detail') {
    return decimal.format(value.toFixed(0));
  }
}

interface GeranteSerieParams {
  name: string;
  data: ChartData[];
  xAxisIndex: number;
  color: string;
  displayedChart: ChartKind;
}

export function generateSerie({ name, data, xAxisIndex, color, displayedChart }: GeranteSerieParams) {
  return {
    name,
    type: 'bar',
    data,
    xAxisIndex,
    color,
    label: {
      textBorderWidth: 3,
      textBorderColor: 'inherit',
      show: true,
      position: 'inside',
      color: '#fff',
      formatter: value => formatTimeSeriesValues(value.data.value[1], displayedChart),
    },
  };
}

export function updateSeriesData(seriesData, allSeries, isActualCycleSelected) {
  const otherGroupsSeries = allSeries.filter(series => !seriesData.includes(series));
  const actualCycleCategories = allSeries.filter(series => series.xAxisIndex === 1 || series.xAxisIndex === 3);

  const updatedData = allSeries.map(series => ({
    ...series,
    name:
      otherGroupsSeries.includes(series) || (!isActualCycleSelected && actualCycleCategories.includes(series))
        ? ''
        : series.name,
    data:
      otherGroupsSeries.includes(series) || (!isActualCycleSelected && actualCycleCategories.includes(series))
        ? []
        : series.data,
  }));

  return updatedData;
}
