import { FilterSelect, FilterCycle, SalesForceSelect } from './components';
import { useFiltersList, useCyclesList } from '@/shared/hooks';
import { useFilters } from '@/features';
import { Flex } from '@grupoboticario/flora-react';

export function MainDashboardFilters() {
  const { isLoading: isLoadingCycles } = useCyclesList();
  const { isLoading: isLoadingFilters } = useFiltersList();
  const { canViewSelector } = useFilters();

  const isLoading = isLoadingFilters || isLoadingCycles;

  return (
    <Flex gap="$4" wrap="wrap" css={{ padding: '$4 $6', width: '100%' }}>
      <FilterCycle isLoading={isLoadingCycles} />
      {canViewSelector && <SalesForceSelect isLoading={isLoading} />}
      <FilterSelect id="region" labelText="Regional" isLoading={isLoading} toggleAll />
      <FilterSelect id="uf" labelText="Estado" isLoading={isLoading} toggleAll />
      <FilterSelect id="cp" labelText="Franqueado (CP)" isLoading={isLoading} toggleAll />
      <FilterSelect id="cs" labelText="Espaço revendedor (CS)" isLoading={isLoading} toggleAll />
      <FilterSelect id="sector" labelText="Setor" isLoading={isLoading} toggleAll />
    </Flex>
  );
}
