import { getImageMeuClubeGb } from '@/shared/assets';
import { Hidden, Flex, Image, Text } from '@grupoboticario/flora-react';
import { meuClubeGbLevelNameMap } from '@/shared/format';
import { StyledPercentage } from '@/shared/components';

export function GbClubLevelName({ level }) {
  const isNotALevel = level === 'indefinido' || level === 'novoRevendedor';
  const isTotal = level === 'Total';
  return (
    <Flex css={{ width: '120px' }} align="center" wrap="nowrap">
      {!isNotALevel && !isTotal && (
        <Hidden only={['mobile']}>
          <Image
            css={{
              marginInlineEnd: '$3',
              width: '40px',
              height: '40px',
              borderRadius: '100%',
              backgroundColor: 'rgba(188, 188, 188, 0.2)',
            }}
            src={getImageMeuClubeGb(level)}
            alt={`Meu Clube GB ${level}`}
            loading="lazy"
          />
        </Hidden>
      )}
      <Flex direction="column">
        <Text css={{ textAlign: 'left' }} size="bodyMediumStandard">
          {isNotALevel ? 'Sem Classificação' : meuClubeGbLevelNameMap[level] || level}
        </Text>
        {isNotALevel && <StyledPercentage align="left">{meuClubeGbLevelNameMap[level]} </StyledPercentage>}
      </Flex>
    </Flex>
  );
}
