import { PageTitle, TabNavigation } from '@/shared/components';
import { OverviewDashboard, OverviewTimeSeries, FilterDrawer } from '@/features';
import { usePermissions } from '@/shared/hooks';
import { Flex } from '@grupoboticario/flora-react';

export function Overview() {
  const { data: hasPermission } = usePermissions('gr-dashboard-overview-timeseries');

  return (
    <>
      <Flex align="center" justify="space-between">
        <PageTitle>Visão Geral</PageTitle>
        <FilterDrawer />
      </Flex>
      {hasPermission ? (
        <TabNavigation consolidated={<OverviewDashboard />} historical={<OverviewTimeSeries />} />
      ) : (
        <OverviewDashboard />
      )}
    </>
  );
}
