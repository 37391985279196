import { clsx } from 'clsx';
import { Flex, Text } from '@grupoboticario/flora-react';
import { Card, CardTitle } from '@/shared/components';

interface IndicatorCardProps {
  title: string;
  tooltip: string;
  gridArea: string;
  value: string;
  goal?: string;
  percentage?: number;
  isLoading: boolean;
  auxiliarText?: string;
  achievement?: string;
  showGoalLabel?: boolean;
}

export function IndicatorCardRenevue({
  title,
  tooltip,
  gridArea,
  value,
  goal,
  isLoading,
  auxiliarText,
  achievement,
  showGoalLabel = false,
}: IndicatorCardProps) {
  return (
    <Card css={{ gridArea, maxWidth: '100%' }}>
      <Flex direction="column" justify="center" css={{ height: '100%', flex: '1 1 auto' }}>
        <CardTitle title={title} tooltip={tooltip} />
        <Text
          size="subTitleDeskBold"
          weight="heavy"
          className={clsx(isLoading && 'loading-placeholder')}
          css={{
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Text>
        {auxiliarText && (
          <Text
            className={clsx(isLoading && 'loading-placeholder loading-placeholder-subtitle')}
            size="exceptionsAuxiliarMedium"
            color="$nonInteractiveAuxiliar"
            css={{ marginBottom: '8px' }}
          >
            {auxiliarText}
          </Text>
        )}
        {goal && (
          <>
            {showGoalLabel && (
              <Text
                size="exceptionsAuxiliarMedium"
                color="$nonInteractiveAuxiliar"
                css={{ textTransform: 'uppercase' }}
              >
                Meta
              </Text>
            )}
            <Flex css={{ alignItems: 'center' }}>
              <Text
                className={clsx(isLoading && 'loading-placeholder loading-placeholder-subtitle')}
                color="$nonInteractiveAuxiliar"
                size="bodyLargeStandardMedium"
                css={{
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {goal}
              </Text>
              {achievement && (
                <Text
                  size="bodyLargeStandardMedium"
                  className={clsx(isLoading && 'loading-placeholder loading-placeholder-subtitle')}
                  css={{
                    color: Number(achievement) >= 100 ? 'green' : 'red',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginLeft: '8px',
                  }}
                >
                  {achievement}
                </Text>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </Card>
  );
}
