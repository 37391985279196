import { Flex, Button, Box } from '@grupoboticario/flora-react';
import { ThreeLinesHorizontal2Icon } from '@grupoboticario/flora-react-icons';

export function DrawerButton({ isLoading, setIsDrawerOpen, applyedFilters }) {
  return (
    <Box css={{ position: 'relative' }}>
      {applyedFilters.length > 0 && (
        <Flex
          css={{
            color: '$actionableContent',
            fontSize: '$restricted',
            position: 'absolute',
            borderRadius: '100%',
            border: '2px solid $nonInteractiveAltPredominant',
            backgroundColor: '$nonInteractiveEmphasis',
            padding: '$2',
            top: '-10px',
            right: '-10px',
            width: '$4',
            height: '$4',
          }}
          align="center"
          justify="center"
        >
          {applyedFilters.length}
        </Flex>
      )}
      <Button
        hierarchy="secondary"
        has="iconLeft"
        icon={<ThreeLinesHorizontal2Icon />}
        onClick={() => setIsDrawerOpen(true)}
        disabled={isLoading}
      >
        Filtros
      </Button>
    </Box>
  );
}
