import { Flex, Text, styled, Card, Button } from '@grupoboticario/flora-react';
import { FinanceModeIcon, SquareArrowRightUpIcon } from '@grupoboticario/flora-react-icons';
import { Link, useNavigate } from 'react-router-dom';

const PageContainer = styled('div', {
  maxWidth: '1360px',
  marginInline: 'auto',
  padding: '$6',
});

export function About() {
  const navigate = useNavigate();

  function handleClick(destination) {
    navigate(destination);
  }

  return (
    <PageContainer>
      <Flex css={{ marginTop: '32px' }} align="center" justify="center" direction="column">
        <FinanceModeIcon size="52px" color="$nonInteractiveEmphasis" />
        <Text css={{ marginTop: '16px' }} size="subTitleDeskRegular" color="$nonInteractiveEmphasis">
          Memória de Cálculo dos Indicadores
        </Text>
        <Text
          css={{ maxWidth: '620px', marginTop: '8px' }}
          size="bodyLargeShortRegular"
          align="center"
          color="$nonInteractivePredominant"
        >
          Nesta página, você encontrará uma lista completa de indicadores disponíveis na VDI, incluindo seus nomes,
          descrições detalhadas e memórias de cálculo. Utilize o campo de pesquisa para encontrar o indicador deseja
        </Text>
      </Flex>
      <Flex css={{ marginTop: '72px' }} gap="$4">
        <Card css={{ maxWidth: '648px', padding: '32px' }}>
          <Flex direction="column" gap="$4" css={{ height: '100%' }} justify="space-between">
            <Flex direction="column" gap="$4">
              <Text size="subTitleDeskRegular" color="$nonInteractiveEmphasis">
                Indicadores de Força de vendas
              </Text>
              <Text size="bodyLargeStandardRegular" color="$nonInteractivePredominant">
                Venda Direta Interativa
              </Text>
              <Text size="bodyLargeShortRegular" color="$nonInteractivePredominant">
                Os indicadores relacionados à força de vendas são focados na operação diária e no desempenho específico
                da equipe de vendas. Eles refletem as regras e práticas operacionais de ativação comercial e fornecem
                insights claros sobre a eficiência e performance da equipe.
              </Text>
            </Flex>
            <Flex gap="$2">
              <Button
                onClick={() => handleClick('/visao-geral')}
                css={{ padding: '0 $4' }}
                size="small"
                hierarchy="secondary"
                icon={<SquareArrowRightUpIcon />}
              >
                Acessar indicadores
              </Button>
              <Button onClick={() => handleClick('/memoria-de-calculo')} size="small" hierarchy="tertiary">
                Saiba mais
              </Button>
            </Flex>
          </Flex>
        </Card>
        <Card css={{ maxWidth: '648px', padding: '32px' }}>
          <Flex direction="column" gap="$4" css={{ height: '100%' }} justify="space-between">
            <Flex direction="column" gap="$4">
              <Text size="subTitleDeskRegular" color="$nonInteractiveEmphasis">
                Indicadores de performance
              </Text>
              <Text size="bodyLargeStandardRegular" color="$nonInteractivePredominant">
                Gestão Integrada - Extranet
              </Text>
              <Text size="bodyLargeShortRegular" color="$nonInteractivePredominant">
                Os indicadores consistem em grupo de indicadores chaves, utilizados para monitorar o desempenho e a
                performance no canal VD.
              </Text>
            </Flex>
            <Flex gap="$2">
              <Link
                to="https://extranet.grupoboticario.com.br/mf/landing-page/manual-de-operacoes/lojas/mapa-de-indicadores"
                target="_blank"
              >
                <Button css={{ padding: '$2 $4' }} size="small" hierarchy="secondary" icon={<SquareArrowRightUpIcon />}>
                  Acessar indicadores
                </Button>
              </Link>
              <Link to="https://extranet.grupoboticario.com.br/mfe/gi/" target="_blank">
                <Button size="small" hierarchy="tertiary">
                  Saiba mais
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    </PageContainer>
  );
}
