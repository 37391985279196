export const gridSettings = (showActiveBase = false) => {
  if (showActiveBase) {
    return {
      '@mobile': {
        gridTemplateAreas: `
      "active-base"
      "total-resellers"
      "distinct-resellers"
      "active-base-percentage"
      "problems-chart"
      `,
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      '@tablet': {
        gridTemplateAreas: `
      "active-base active-base total-resellers total-resellers"
      "distinct-resellers distinct-resellers active-base-percentage active-base-percentage"
      "problems-chart problems-chart problems-chart problems-chart"
      `,
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      '@desktop': {
        gridTemplateAreas: `
      "active-base active-base active-base total-resellers total-resellers total-resellers distinct-resellers distinct-resellers distinct-resellers active-base-percentage active-base-percentage active-base-percentage"
      "problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart"
      `,
        gridTemplateColumns: 'repeat(12, 1fr)',
      },
    };
  }
  return {
    '@mobile': {
      gridTemplateAreas: `
    "total-resellers"
    "distinct-resellers"
    "active-base-percentage"
    "problems-chart"
    `,
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    '@tablet': {
      gridTemplateAreas: `
    "total-resellers total-resellers total-resellers total-resellers"
    "distinct-resellers distinct-resellers active-base-percentage active-base-percentage"
    "problems-chart problems-chart problems-chart problems-chart"
    `,
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    '@desktop': {
      gridTemplateAreas: `
    "total-resellers total-resellers total-resellers total-resellers distinct-resellers distinct-resellers distinct-resellers distinct-resellers active-base-percentage active-base-percentage active-base-percentage active-base-percentage"
    "problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart problems-chart"
    `,
      gridTemplateColumns: 'repeat(12, 1fr)',
    },
  };
};
