import { useState } from 'react';
import { Box, Flex, Text, Input } from '@grupoboticario/flora-react';
import { MagnifyingGlassIcon } from '@grupoboticario/flora-react-icons';
import { CalculationLogList } from '@/features/IndicatorSearch';
import { useCalculationLog } from '@/shared/hooks';

export function IndicatorsSearch() {
  const { data, isLoading } = useCalculationLog();
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <Flex css={{ marginTop: '72px' }} justify="space-between">
        <Text size="subTitleDeskMedium">Conheça nossos indicadores</Text>
        <Box>
          <Input
            disabled={isLoading}
            onChange={e => setSearchTerm(e.target.value)}
            css={{ width: '355px' }}
            data-testid="search-calculation-log-input"
            id="search-calculation-log-input"
            leftIcon={<MagnifyingGlassIcon />}
            labelText="Pesquisar:"
            required
            placeholder="Nome ou termo do indicador"
          />
        </Box>
      </Flex>
      <CalculationLogList
        searchTerm={searchTerm}
        isLoading={isLoading}
        calculationLogData={data}
        data-testid="calculation-log-card"
      />
    </>
  );
}
