export function sortColumnsByAscendingName({ columns }) {
  return columns?.sort((a, b) => a.name.localeCompare(b.name));
}

export function sortColumnsByCustomOrder({ columns, customOrderArray }) {
  return columns?.sort((a, b) => customOrderArray.indexOf(a.name) - customOrderArray.indexOf(b.name));
}

export function sortObjectByCustomOrder<ObjType>({
  obj,
  customOrderArray,
  key,
}: {
  obj: ObjType[];
  customOrderArray: Array<string>;
  key: string;
}) {
  return obj?.sort((a, b) => customOrderArray.indexOf(a[`${key}`]) - customOrderArray.indexOf(b[`${key}`]));
}
