import { useQuery } from '@tanstack/react-query';
import { fetchDashboard } from '@/shared/api';
import { useFilters } from '@/features';

interface DashboardDataParams {
  queryKey: string;
  path: string;
  parent?: string;
  enabled?: boolean;
}

export const useGetDashboardData = ({ queryKey, path, parent, enabled }: DashboardDataParams) => {
  const {
    requestData: { cycle, model, sector },
  } = useFilters();

  return useQuery({
    staleTime: 1000 * 60 * 60,
    queryKey: [queryKey, path, parent, cycle, model, sector],
    enabled: enabled ?? model !== null,
    queryFn: ({ signal }) =>
      fetchDashboard({
        cycle,
        sector,
        model,
        path,
        signal,
        parent,
      }),
  });
};
