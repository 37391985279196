import { CardTitle, ReactECharts, ReactEChartsProps } from '@/shared/components';
import { Flex } from '@grupoboticario/flora-react';
import { decimalNoFraction, percentNoFraction } from '@/shared/format';
import { useSubreason } from '../context';

export function RelationStackedChart({ chartData, chartTitle, isLoading, tooltip, onClick }) {
  const { reasons } = useSubreason();
  const color = reasons.map(reason => reason.color);
  const isMobile = window.innerWidth < 768;

  const dataInAscedingOrder = chartData?.sort((a, b) => b.resellersActive - a.resellersActive);

  const options: ReactEChartsProps['option'] = {
    dataset: {
      dimensions: ['name', 'resellersActive', 'percentageActive', 'resellersReceptive', 'percentageReceptive'],
      source:
        dataInAscedingOrder?.map(column => [
          column.name,
          column.resellersActive,
          column.percentageActive,
          column.resellersReceptive,
          column.percentageReceptive,
        ]) ?? [],
    },
    color,
    xAxis: {
      show: !isMobile,
      type: 'category',
      axisLabel: {
        overflow: 'break',
        margin: 16,
        interval: 0,
        width: 100,
        rich: {
          span: {
            fontWeight: 'bold',
            padding: [4, 0],
          },
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: value => decimalNoFraction.format(value),
      },
    },
    series: [
      {
        name: 'Ativo',
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: params => color[params.dataIndex],
        },
        label: {
          show: !isMobile,
          position: 'inside',
          textBorderColor: 'inherit',
          textBorderWidth: 3,
          formatter: value => percentNoFraction.format(value.data[2] / 100),
        },
        encode: { x: 'name', y: 'resellersActive' },
      },
      {
        name: 'Receptivo',
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: params => color[params.dataIndex],
          decal: {
            backgroundColor: '#ffffff60',
            dashArrayX: [2, 0],
            dashArrayY: [2, 4],
            symbolSize: 1,
            rotation: -Math.PI / 4,
          },
        },
        label: {
          show: !isMobile,
          position: 'inside',
          textBorderColor: 'inherit',
          textBorderWidth: 3,
          formatter: value => percentNoFraction.format(value.data[4] / 100),
        },
        encode: { x: 'name', y: 'resellersReceptive' },
      },
    ],
    grid: {
      top: '8%',
      right: '2%',
      bottom: '12%',
      left: '3%',
      containLabel: true,
    },
    legend: {
      selectedMode: false,
      show: !isLoading,
      itemWidth: 16,
      itemHeight: 16,
      bottom: 0,
      borderRadius: 0,
      itemStyle: {
        color: '#fff',
        borderColor: '#ccc',
        borderWidth: 1,
      },
    },
    tooltip: {
      trigger: 'axis',
      borderWidth: 0,
      confine: true,
      formatter: value => {
        return `
        <div style="margin-bottom: 8px; border-bottom: 1px solid #ccc; padding-bottom: 8px; font-weight: 600">${chartTitle}</div>
        <div style="display: flex; align-items: flex-end; justify-content: space-between">
          <div style="padding-right: 20px; font-weight: 500">${value[0].axisValue}</div>
          <div style="display: flex; align-items: center; font-weight: 400">
            <div style="display: flex; align-items: start; flex-direction: column">
              Ativo
              <div>
                ${value[0].marker} ${decimalNoFraction.format(value[0].data[1])} (${percentNoFraction.format(value[0].data[2] / 100)})
              </div>
            </div>
            <div style="display: flex; align-items: start; flex-direction: column; margin-left: 12px;">
              Receptivo
              <div>
                <span
                  style="display: inline-block;
                  width: 10px; height: 10px;
                  background:
                  repeating-linear-gradient(
                    135deg,
                    ${value[1].color},
                    ${value[1].color} 1.5px,
                    ${value[1].color}70 1px,
                    ${value[1].color}70 3px
                    );
                  border-radius: 50%;
                  margin-right: 4px">
                </span>
                ${decimalNoFraction.format(value[0].data[3])} (${percentNoFraction.format(value[0].data[4] / 100)})
              </div>
            </div>
          </div>
        </div>`;
      },
      axisPointer: {
        type: 'none',
      },
    },
  };

  return (
    <Flex gap="$2" direction="column" css={{ width: '100%', gridArea: 'stacked-bar-chart' }}>
      <CardTitle title={chartTitle} tooltip={tooltip} />
      <Flex justify="center">
        <ReactECharts
          option={{ ...options }}
          style={{
            width: '100%',
            height: '100%',
            minHeight: '600px',
          }}
          onClick={onClick}
          loading={isLoading}
        />
      </Flex>
    </Flex>
  );
}
