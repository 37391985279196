import { ReactEChartsProps } from '@/shared/components';
import { decimal, decimalNoFraction, percent, percentNoFraction } from '../number';
import { formatTimeSeriesValues } from '@/features';

interface TooltipProps {
  title?: string;
  marker: string;
  name: string;
  amount: number;
  percentage: number;
}

export function generateTooltip({ title = '', marker, name, amount, percentage }: TooltipProps) {
  return `<div>
      ${title && `<p>${title}</p>` + '<hr />'}
      <div style="display: 'flex'; flex-direction: row">
        <span>${marker}</span>
        <strong>${name}</strong>
        <span style="padding-left: 20px">${amount ? decimal.format(amount) : ''}</span>
        <span style="padding-left: 10px">${percentage}%</span>
      </div>
    </div>`;
}

export function generateTimeSeriesTooltip({ value, displayedChart }) {
  return `<div>
      <p>Ciclo ${value[0].axisValue}</p>
      <hr />
      <div style="display: flex; flex-direction: column; justify-content: space-between">
        ${value
          .map(item => {
            return `
            <div style="display: flex; flex-direction: row; justify-content: space-between">
              <div>
                <span>${item.marker}</span>
                <strong>${item.seriesName}</strong>
              </div>
              <span style="padding-left: 40px">${formatTimeSeriesValues(item.value[1], displayedChart)}</span>
            </div>
          `;
          })
          .join('')}
        </div>
    </div>`;
}

export function setTooltip(tooltipTitle?): ReactEChartsProps['option']['tooltip'] {
  return {
    trigger: 'item',
    borderRadius: 6,
    borderColor: '#fff',
    axisPointer: {
      type: 'shadow',
    },
    formatter: value => {
      return generateTooltip({
        title: tooltipTitle,
        marker: value.marker,
        name: value.name,
        amount: value.data.resellers,
        percentage: value.value,
      });
    },
  };
}

export const formatTooltipBar = (title: string, value, formatter) => {
  return `
    <div style="margin-bottom: 8px; border-bottom: 1px solid #ccc; padding-bottom: 8px">${title}</div>
    <div style="display: flex; align-items: center; justify-content: space-between; width: 240px">
      <div>${value.name}</div>
      <div style="display: flex; align-items: center">
        <div style="width: 12px; height: 12px; background-color: ${value.color}; border-radius: 50%; margin: 0 8px;"></div>
        <div>${formatter.format(formatter === percent || formatter === percentNoFraction ? value.value / 100 : value.value)}</div>
      </div>
    </div>
  `;
};

export function relationTooltip(title, subtitle, marker, value, percentage) {
  return `
    <div style="margin-bottom: 8px; border-bottom: 1px solid #ccc; padding-bottom: 8px; font-weight: 600">${title}</div>
    <div style="display: flex; align-items: center; justify-content: space-between">
      <div style="padding-right: 20px">${subtitle}</div>
      <div style="display: flex; align-items: center">
        ${marker} ${decimalNoFraction.format(value)} (${percentNoFraction.format(percentage / 100)})
      </div>
    </div>
  `;
}
