import {
  getCodesInSelectedRegionAndUfs,
  getRelatedStructures,
  getRelatedStructuresTypes,
  getSelectedAndEnabledCodes,
  setApplyedFilters,
  setInitialFilters,
  setSelectedSalesForceModel,
  updateSelection,
} from '@/features';
import { createContext, useContext, useReducer } from 'react';

export const FiltersContext = createContext(null);
export const FiltersDispatchContext = createContext(null);

export type StructureType = 'region' | 'uf' | 'cp' | 'cs' | 'sector';

export type Model = 'FVC' | 'CAMPO' | 'SELECTOR';

interface Structure {
  code: number | string;
  name: string;
  type: StructureType;
  model?: Model;
  parents?: StructureFilterOption[];
}

interface Cycle {
  code: number;
  name: string;
}

interface FilterOption {
  selected: boolean;
  enabled: boolean;
}

interface SalesForce {
  name: string;
  model: Model;
}

interface CycleFilterOption extends FilterOption, Cycle {}
export interface StructureFilterOption extends FilterOption, Structure {}
interface SalesForceOption extends FilterOption, SalesForce {}

type SetCyclesAction = {
  type: 'SET_CYCLES';
  payload: Cycle[];
};

type SelectCycleAction = {
  type: 'SELECT_CYCLE';
  payload: number[];
};

type SetStructuresAction = {
  type: 'SET_FILTERS';
  payload: { structures: { FVC: Structure[]; CAMPO: Structure[] }; model: Model };
};

type SelectFilterAction = {
  type: 'SELECT_FILTER';
  payload: {
    structureType: StructureType;
    selectedCodes: string[];
  };
};

type ClearFiltersSelectionAction = {
  type: 'CLEAR_FILTERS_SELECTION';
};

type SelectAllFilterAction = {
  type: 'SELECT_ALL_OPTIONS';
  payload: StructureType;
};

type ToggleSalesForceAction = {
  type: 'TOGGLE_SALES_FORCE';
};

type SetRequestDataAction = {
  type: 'SET_REQUEST_DATA';
  payload: boolean;
};

type DiscardChangesAction = {
  type: 'DISCARD_CHANGES';
};

type FilterActions =
  | SetCyclesAction
  | SelectCycleAction
  | SetStructuresAction
  | SelectFilterAction
  | ClearFiltersSelectionAction
  | SelectAllFilterAction
  | ToggleSalesForceAction
  | SetRequestDataAction
  | DiscardChangesAction;

interface FilterState {
  cycles: CycleFilterOption[];
  structures: { FVC: StructureFilterOption[]; CAMPO: StructureFilterOption[] };
  salesForce: SalesForceOption[];
  selectedCycles: number[];
  selectedSectorCodes: number[];
  salesForceModel: Model;
  canViewSelector: boolean;
  isFirstRequest: boolean;
  hasDiscardedChanges: boolean;
  requestData: { cycle: string; sector: string; model: Model };
  hasChanges: boolean;
  selectedCodesInLastRequest: (number | string)[];
  enabledCodesInLastRequest: (number | string)[];
  applyedFilters: string[];
}

const initialState: FilterState = {
  cycles: [],
  structures: { FVC: [], CAMPO: [] },
  salesForce: [
    { name: 'Força de Vendas Local', model: 'CAMPO', selected: false, enabled: false },
    { name: 'Força de Vendas Central', model: 'FVC', selected: false, enabled: false },
  ],
  selectedCycles: null,
  selectedSectorCodes: null,
  salesForceModel: null,
  canViewSelector: false,
  requestData: { cycle: null, sector: null, model: null },
  hasChanges: false,
  hasDiscardedChanges: false,
  isFirstRequest: true,
  selectedCodesInLastRequest: null,
  enabledCodesInLastRequest: null,
  applyedFilters: [],
};

function reducer(state: FilterState, action: FilterActions) {
  switch (action.type) {
    case 'SET_CYCLES': {
      const latestCycleCode = action.payload[0].code;

      state.cycles = action.payload.map(cycle => ({
        ...cycle,
        selected: latestCycleCode === cycle.code,
        enabled: true,
      }));

      state.selectedCycles = [latestCycleCode];
      state.requestData.cycle = [latestCycleCode].join(',');

      return {
        ...state,
      };
    }
    case 'SELECT_CYCLE': {
      state.hasChanges = true;
      state.hasDiscardedChanges = false;

      const selectedCycles = action.payload;

      state.cycles = state.cycles.map(cycle => ({
        ...cycle,
        selected: selectedCycles.includes(cycle.code),
      }));

      state.selectedCycles = selectedCycles;

      const latestCycleCode = state.cycles[0].code;
      const onlyLastCycleSelected = selectedCycles.every(cycle => cycle === latestCycleCode);

      if (onlyLastCycleSelected) {
        state.applyedFilters = state.applyedFilters.filter(item => item !== 'cycle');
        return { ...state };
      }

      if (!state.applyedFilters.includes('cycle')) {
        state.applyedFilters = [...state.applyedFilters, 'cycle'];
      }

      return { ...state };
    }
    case 'SET_FILTERS': {
      const { structures, model: userModel } = action.payload;
      state.canViewSelector = userModel === 'SELECTOR';
      const { isFirstRequest, hasDiscardedChanges } = state;

      const hasFvc = structures?.FVC.length > 0;
      const hasFvl = structures?.CAMPO.length > 0;

      state.salesForce = setSelectedSalesForceModel(state, userModel, hasFvc, hasFvl);

      const selectedSalesForce = state.salesForce.find(item => item.selected)?.model;
      const isFvcSelected = selectedSalesForce === 'FVC';

      state.salesForceModel = selectedSalesForce;

      if (hasDiscardedChanges) {
        const { enabledCodesInLastRequest, selectedCodesInLastRequest } = state;
        state.structures.FVC = structures?.FVC.map(item => ({
          ...item,
          selected: selectedCodesInLastRequest.includes(item.code),
          enabled: enabledCodesInLastRequest.includes(item.code),
          parents: item.parents.map(parent => ({
            ...parent,
            selected: selectedCodesInLastRequest.includes(parent.code),
            enabled: enabledCodesInLastRequest.includes(parent.code),
          })),
        }));
        state.structures.CAMPO = structures?.CAMPO.map(item => ({
          ...item,
          selected: selectedCodesInLastRequest.includes(item.code),
          enabled: enabledCodesInLastRequest.includes(item.code),
          parents: item.parents.map(parent => ({
            ...parent,
            selected: selectedCodesInLastRequest.includes(parent.code),
            enabled: enabledCodesInLastRequest.includes(parent.code),
          })),
        }));
      }

      if (!hasDiscardedChanges) {
        state.structures = {
          FVC: setInitialFilters(structures?.FVC, isFvcSelected),
          CAMPO: setInitialFilters(structures?.CAMPO, !isFvcSelected),
        };

        state.selectedSectorCodes = state.structures[selectedSalesForce]
          ?.filter(item => item.selected)
          .map(item => item.code);

        state.hasDiscardedChanges = false;
        state.applyedFilters = state.applyedFilters.filter(item => item === 'cycle');
      }

      if (isFirstRequest) {
        state.requestData.model = state.salesForce.find(item => item.selected)?.model;

        state.requestData.sector = state.structures[state.salesForceModel]
          ?.filter(item => item.selected)
          .map(item => item.code)
          .join(',');

        state.isFirstRequest = false;
        const { enabledCodesInLastRequest, selectedCodesInLastRequest } = getSelectedAndEnabledCodes(
          state.structures[state.salesForceModel],
        );
        state.enabledCodesInLastRequest = enabledCodesInLastRequest;
        state.selectedCodesInLastRequest = selectedCodesInLastRequest;

        return {
          ...state,
        };
      }

      return { ...state };
    }
    case 'SELECT_FILTER': {
      const { selectedCodes, structureType } = action.payload;
      const { parentsTypes, childrenTypes } = getRelatedStructuresTypes(structureType);
      state.hasChanges = true;

      const salesForceModel = state.salesForceModel;

      const noneSelectedInStructure = selectedCodes.length === 0;
      const relatedStructures = getRelatedStructures(selectedCodes, structureType, state.structures[salesForceModel]);

      const relatedSectorCodes = relatedStructures.map(item => item.code);
      const relatedParentCodes = relatedStructures.flatMap(item => item.parents.map(parent => parent.code));

      if (noneSelectedInStructure) {
        state.applyedFilters = state.applyedFilters.filter(item => item !== structureType);

        state.structures[salesForceModel] = state.structures[salesForceModel].map(item => {
          return {
            ...item,
            selected: relatedSectorCodes.includes(item.code),
            enabled: true,
            parents: item.parents.map(parent => ({
              ...parent,
              selected: parentsTypes.includes(parent.type) ? parent.selected : relatedParentCodes.includes(parent.code),
              enabled: childrenTypes.includes(parent.type)
                ? true
                : parent.type === structureType
                  ? true
                  : parent.enabled,
            })),
          };
        });

        state.selectedSectorCodes = state.structures[salesForceModel]
          .filter(item => item.selected)
          .map(item => item.code);

        setApplyedFilters(state, salesForceModel);

        return { ...state };
      }

      if (structureType === 'cp') {
        const codesInSelectedRegionAndUfs = getCodesInSelectedRegionAndUfs(state.structures[salesForceModel]);

        const isAllSelectedCpInSelectedRegionAndUfs = selectedCodes.every(code =>
          codesInSelectedRegionAndUfs.includes(code),
        );

        const lastAddedItem = selectedCodes[selectedCodes.length - 1];

        const lastAddedItemStructure = getRelatedStructures(
          [lastAddedItem],
          structureType,
          state.structures[salesForceModel],
        );

        const lastAddedItemSectorCodes = lastAddedItemStructure.map(item => item.code);
        const lastAddedItemParentsCodes = lastAddedItemStructure.flatMap(item =>
          item.parents.map(parent => parent.code),
        );
        const lastAddedItemCodes = [...lastAddedItemSectorCodes, ...lastAddedItemParentsCodes];

        const isLastItemInSelectedRegionOrUf = lastAddedItemParentsCodes.some(parentCode =>
          codesInSelectedRegionAndUfs.includes(parentCode),
        );

        const isSelected = (code, relatedCodes) => {
          if (isAllSelectedCpInSelectedRegionAndUfs) {
            return codesInSelectedRegionAndUfs.includes(code) && relatedCodes.includes(code);
          }
          if (!isLastItemInSelectedRegionOrUf) {
            return (
              lastAddedItemCodes.includes(code) ||
              (codesInSelectedRegionAndUfs.includes(code) && relatedCodes.includes(code))
            );
          }
          return relatedCodes.includes(code);
        };

        state.structures[salesForceModel] = state.structures[salesForceModel].map(item => ({
          ...item,
          selected: isSelected(item.code, relatedSectorCodes),
          enabled: isSelected(item.code, relatedSectorCodes),
          parents: item.parents.map(parent => ({
            ...parent,
            selected:
              parent.type === 'cp' ? selectedCodes.includes(parent.code) : isSelected(parent.code, relatedParentCodes),
            enabled: parent.type === structureType ? parent.enabled : isSelected(parent.code, relatedParentCodes),
          })),
        }));

        state.selectedSectorCodes = state.structures[salesForceModel]
          .filter(item => item.selected)
          .map(item => item.code);

        setApplyedFilters(state, salesForceModel);

        return { ...state };
      }

      state.structures[salesForceModel] = updateSelection(
        state.structures[salesForceModel],
        relatedSectorCodes,
        relatedParentCodes,
        structureType,
      );

      state.selectedSectorCodes = state.structures[salesForceModel]
        .filter(item => item.selected)
        .map(item => item.code);

      setApplyedFilters(state, salesForceModel);

      return { ...state };
    }
    case 'CLEAR_FILTERS_SELECTION': {
      const salesForceModel = state.salesForce.find(item => item.selected)?.model;
      state.hasChanges = true;

      state.structures[salesForceModel] = state.structures[salesForceModel]?.map(item => ({
        ...item,
        selected: false,
        enabled: true,
        parents: item.parents.map(parent => ({ ...parent, selected: false, enabled: true })),
      }));

      state.selectedSectorCodes = [];

      if (state.applyedFilters.includes('cycle')) {
        state.applyedFilters = ['cycle', 'region', 'uf', 'cs', 'cp', 'sector'];
        return {
          ...state,
        };
      }

      state.applyedFilters = ['region', 'uf', 'cs', 'cp', 'sector'];

      return {
        ...state,
      };
    }
    case 'SELECT_ALL_OPTIONS': {
      const { payload: structureType } = action;
      const { childrenTypes } = getRelatedStructuresTypes(structureType);
      state.hasChanges = true;

      const salesForceModel = state.salesForce.find(item => item.selected)?.model;

      const allEnabledOptionsSelected =
        structureType === 'sector'
          ? state.structures[salesForceModel].filter(item => item.enabled).every(item => item.selected)
          : state.structures[salesForceModel].every(item =>
              item.parents.filter(item => item.enabled && item.type === structureType).every(item => item.selected),
            );

      const selectedStrucureEnabledCodes =
        structureType === 'sector'
          ? state.structures[salesForceModel].filter(item => item.enabled).map(item => item.code)
          : state.structures[salesForceModel].flatMap(item =>
              item.parents.filter(item => item.enabled && item.type === structureType).map(item => item.code),
            );

      const relatedItems = getRelatedStructures(
        selectedStrucureEnabledCodes,
        structureType,
        state.structures[salesForceModel],
      );

      const relatedSectorCodes = relatedItems.map(item => item.code);
      const relatedParentsCodes = relatedItems.flatMap(item => item.parents.map(parent => parent.code));

      const relatedStructuresCodes = [...relatedSectorCodes, ...relatedParentsCodes];

      const selectedRegionAndUfs = getCodesInSelectedRegionAndUfs(state.structures[salesForceModel]);

      const isItemSelected = item => {
        if (allEnabledOptionsSelected) {
          if (structureType === item.type) {
            return false;
          }
          if (childrenTypes.includes(item.type)) {
            return false;
          }
          return item.enabled && item.selected;
        }

        if (structureType === 'cp') {
          return (
            (selectedRegionAndUfs.includes(item.code) || selectedRegionAndUfs.length === 0) &&
            relatedStructuresCodes.includes(item.code)
          );
        }

        if (structureType === item.type) {
          return item.enabled;
        }

        return relatedStructuresCodes.includes(item.code);
      };

      const isItemEnabled = item => {
        if (allEnabledOptionsSelected) {
          if (childrenTypes.includes(item.type)) {
            return true;
          }
        }
        if (structureType === 'cp') {
          return (
            (selectedRegionAndUfs.includes(item.code) || selectedRegionAndUfs.length === 0) &&
            relatedStructuresCodes.includes(item.code)
          );
        }

        return relatedStructuresCodes.includes(item.code);
      };

      state.structures[salesForceModel] = state.structures[salesForceModel].map(item => {
        return {
          ...item,
          selected: isItemSelected(item),
          enabled: isItemEnabled(item),
          parents: item.parents.map(parent => ({
            ...parent,
            selected: isItemSelected(parent),
            enabled: isItemEnabled(parent),
          })),
        };
      });

      state.selectedSectorCodes = state.structures[salesForceModel]
        .filter(item => item.selected)
        .map(item => item.code);

      setApplyedFilters(state, salesForceModel);

      return {
        ...state,
      };
    }
    case 'TOGGLE_SALES_FORCE': {
      state.hasChanges = true;

      const updatedSalesForceSelection = state.salesForce.map(item => ({
        ...item,
        selected: !item.selected,
      }));

      const isFvcSelected = updatedSalesForceSelection.find(item => item.selected).model === 'FVC';

      state.structures = {
        FVC: setInitialFilters(state.structures.FVC, isFvcSelected),
        CAMPO: setInitialFilters(state.structures.CAMPO, !isFvcSelected),
      };

      state.salesForce = updatedSalesForceSelection;

      state.salesForceModel = state.salesForce.find(item => item.selected)?.model;

      state.selectedSectorCodes = state.structures[state.salesForceModel]
        ?.filter(item => item.selected)
        .map(item => item.code);

      return { ...state };
    }
    case 'SET_REQUEST_DATA': {
      const model = state.salesForce.find(item => item.selected)?.model;
      const cycle = state.cycles
        .filter(item => item.selected)
        .map(item => item.code)
        .join(',');
      const sector = state.structures[state.salesForceModel]
        ?.filter(item => item.selected)
        .map(item => item.code)
        .join(',');

      state.requestData = { model, cycle, sector };

      const { enabledCodesInLastRequest, selectedCodesInLastRequest } = getSelectedAndEnabledCodes(
        state.structures[state.salesForceModel],
      );

      state.hasChanges = false;
      state.hasDiscardedChanges = false;
      state.enabledCodesInLastRequest = enabledCodesInLastRequest;
      state.selectedCodesInLastRequest = selectedCodesInLastRequest;
      return { ...state };
    }
    case 'DISCARD_CHANGES': {
      const { model, cycle, sector } = state.requestData;
      const modelForRequest = model.toString();
      const cyclesForRequest = cycle.split(',').map(item => Number(item));
      const sectorsForRequest = sector.split(',').map(item => Number(item));

      const { selectedCycles, selectedSectorCodes, salesForceModel } = state;

      const hasChanges = (dataFromRequest, selectedItems) => {
        if (dataFromRequest.length !== selectedItems.length) {
          return true;
        }
        return dataFromRequest.some((item, index) => item !== selectedItems[index]);
      };

      if (
        hasChanges(sectorsForRequest, selectedSectorCodes) ||
        hasChanges(cyclesForRequest, selectedCycles) ||
        modelForRequest !== salesForceModel
      ) {
        state.cycles = state.cycles.map(cycle => ({
          ...cycle,
          selected: cyclesForRequest.includes(cycle.code),
        }));

        state.structures[salesForceModel] = state.structures[salesForceModel].map(item => {
          return {
            ...item,
            selected: state.selectedCodesInLastRequest?.includes(item.code),
            enabled: state.enabledCodesInLastRequest?.includes(item.code),
            parents: item.parents.map(parent => ({
              ...parent,
              selected: state.selectedCodesInLastRequest?.includes(parent.code),
              enabled: state.enabledCodesInLastRequest?.includes(parent.code),
            })),
          };
        });

        state.salesForce = state.salesForce.map(item => ({
          ...item,
          selected: item.model === modelForRequest,
        }));
        state.selectedCycles = state.cycles.filter(item => item.selected).map(item => item.code);
        state.selectedSectorCodes = sectorsForRequest;
        state.salesForceModel = model;
      }

      state.hasChanges = false;
      state.hasDiscardedChanges = true;
      setApplyedFilters(state, salesForceModel);

      return { ...state };
    }
  }
  // @ts-expect-error always throw error when unknown action
  throw Error('Unknown action: ' + action.type);
}

export function useFilters() {
  return useContext<FilterState>(FiltersContext);
}

export function useFiltersDispatch() {
  return useContext(FiltersDispatchContext);
}

export function FiltersProvider({ children }) {
  const [filters, dispatch] = useReducer(reducer, initialState);
  return (
    <FiltersContext.Provider value={filters}>
      <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  );
}
