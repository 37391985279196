import { Flex, Text } from '@grupoboticario/flora-react';
import { CrossIcon } from '@grupoboticario/flora-react-icons';
import { useFiltersDispatch } from '../../context';
export function FilterTag({ label, onClose, isMaxTag = true, isLoading, structureType }) {
  const dispatch = useFiltersDispatch();
  function handleOnClose() {
    if (isLoading) return;
    if (label === 'Todos selecionados') {
      return dispatch({
        type: `SELECT_ALL_OPTIONS`,
        payload: structureType,
      });
    }
    return onClose();
  }

  return (
    <Flex
      css={{
        backgroundColor: 'rgba(245, 241, 235, 1)',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        fontSize: '14px',
        borderRadius: '4px',
        padding: '2px 8px',
        lineHeight: '20px',
        marginInlineEnd: '4px',
        paddingInlineEnd: '8px',
      }}
      gap="$1"
      align="center"
    >
      <Text css={{ overflow: 'hidden', textOverflow: 'ellipsis', display: 'inline-block', whiteSpace: 'pre' }}>
        {label}
      </Text>
      {!isMaxTag && (
        <Flex
          css={{
            borderRadius: '100%',
            cursor: isLoading ? 'unset' : 'pointer',
          }}
          onClick={handleOnClose}
        >
          <CrossIcon
            css={{
              color: 'rgba(0, 0, 0, 0.45)',
              transition: 'color 0.3s ease',
              '&:hover': {
                color: 'rgba(0, 0, 0, 1)',
              },
            }}
            size="14"
          />
        </Flex>
      )}
    </Flex>
  );
}
