import { styled, CSS } from '@grupoboticario/flora-react';

const Table = styled('table', {
  width: '100%',
  marginTop: '$4',
});

interface StyledTableProps {
  css?: CSS;
  children?: React.ReactNode;
}

export const StyledTable = ({ css, children }: StyledTableProps) => {
  return (
    <Table
      css={{
        '& thead th:first-child': {
          position: 'sticky',
          left: 0,
          zIndex: 2,
        },
        '& tbody td:first-child': {
          position: 'sticky',
          left: 0,
          zIndex: 2,
          backgroundColor: 'inherit',
        },
        whiteSpace: 'nowrap',
        '& thead th': {
          backgroundColor: '$backgroundSecondary',
        },
        '& tbody': {
          '& tr:nth-child(even)': {
            backgroundColor: '$backgroundSecondary',
          },
          '& tr:nth-child(odd)': {
            backgroundColor: 'white',
          },
        },
        ...css,
      }}
    >
      {children}
    </Table>
  );
};
