import { authService } from '@vdi/auth-service';

export async function fetchCalculationLog() {
  const { token } = await authService.getTokenOrRedirect();
  const request = await fetch(`${process.env.API_BASE_URL}/about/indicators`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!request.ok) {
    throw new Error(`Network response was not ok`);
  }

  return request.json();
}
