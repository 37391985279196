import { Select } from 'antd';
import { Flex, Text } from '@grupoboticario/flora-react';
import { useEventTracker } from '@/shared/hooks';
import type { FilterSelectProps } from './types';
import {
  DisabledInput,
  FilterCheckbox,
  FilterTag,
  StructureFilterOption,
  useFilters,
  useFiltersDispatch,
} from '@/features';

const mapFilterNameToEventName: Record<
  'region' | 'uf' | 'cp' | 'cs' | 'sector',
  'região' | 'uf' | 'franqueado' | 'espaco_revendedor' | 'setor'
> = {
  region: 'região',
  uf: 'uf',
  cp: 'franqueado',
  cs: 'espaco_revendedor',
  sector: 'setor',
};

const { Option } = Select;

export function FilterSelect({ id: structureType, labelText, isLoading, toggleAll }: FilterSelectProps) {
  const dispatch = useFiltersDispatch();
  const { structures, salesForce } = useFilters();
  const { clickedOnFilter } = useEventTracker();

  const salesForceModel = salesForce.find(item => item.selected)?.model;

  const parentsOptions = new Map<string, StructureFilterOption>();

  const options = structures[salesForceModel]?.map(({ parents, code, name, selected, enabled }) => {
    if (structureType === 'sector') {
      return { code, name, selected, enabled };
    }

    parents.forEach(parent => {
      if (!parentsOptions.has(parent.code)) {
        parentsOptions.set(parent.code, parent);
      }
    });
  });

  const filterOptions =
    structureType !== 'sector'
      ? Array.from(parentsOptions.values()).filter(parent => parent.type === structureType)
      : options;

  const selectedOptions = filterOptions?.filter(item => item?.selected);
  const selectedOptionsCodes = selectedOptions?.map(item => item.code);
  const everyEnabledOptionSelected = filterOptions?.filter(item => item?.enabled).every(item => item.selected);
  const someSelected = filterOptions?.filter(item => item?.enabled).some(item => item.selected);

  const handleSelect = selectedCodes => {
    const hasSelectedAll = selectedCodes.includes('select-all');
    if (hasSelectedAll) {
      return dispatch({
        type: `SELECT_ALL_OPTIONS`,
        payload: structureType,
      });
    }
    dispatch({
      type: 'SELECT_FILTER',
      payload: { structureType, selectedCodes },
    });
  };

  const showToggleAllAction = toggleAll && filterOptions?.length > 1;
  const hasOnlyOneOption = filterOptions?.length === 1;

  return (
    <Flex direction="column" wrap="wrap" css={{ width: '100%' }}>
      <Text
        as="label"
        size="exceptionsRestrictedRegular"
        css={{
          display: 'flex',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
        }}
        htmlFor={structureType}
        title={labelText}
      >
        {labelText}
      </Text>
      {hasOnlyOneOption ? (
        filterOptions?.map(item => <DisabledInput key={item.code} text={item.name} />)
      ) : (
        <Select
          disabled={isLoading}
          loading={isLoading}
          showSearch
          tagRender={props =>
            everyEnabledOptionSelected ? (
              !props.isMaxTag && (
                <FilterTag
                  isMaxTag={props.isMaxTag}
                  onClose={props.onClose}
                  label="Todos selecionados"
                  isLoading={isLoading}
                  structureType={structureType}
                />
              )
            ) : (
              <FilterTag
                isMaxTag={props.isMaxTag}
                onClose={props.onClose}
                label={props.label}
                isLoading={isLoading}
                structureType={structureType}
              />
            )
          }
          maxTagPlaceholder={omittedValues => <Text>{`+ ${omittedValues.length}`}</Text>}
          style={{ width: '100%' }}
          mode={hasOnlyOneOption ? undefined : 'multiple'}
          filterSort={(optionA, optionB) => {
            if (optionA.value === 'select-all') return -1;
            if (optionB.value === 'select-all') return 1;
            return optionA.value.toString().localeCompare(optionB.value.toString(), undefined, { numeric: true });
          }}
          allowClear
          placeholder="Pesquise ou selecione"
          placement="bottomLeft"
          id={structureType}
          filterOption={(input, options) => options?.title?.toLowerCase().includes(input.toLowerCase())}
          onChange={value => handleSelect(value)}
          maxTagCount={1}
          value={selectedOptionsCodes}
          optionLabelProp="label"
          onFocus={() => clickedOnFilter({ filterName: mapFilterNameToEventName[structureType] })}
        >
          {showToggleAllAction && (
            <Option data-testid="select-all-option" title="Selecionar todos" value="select-all">
              <FilterCheckbox
                selected={everyEnabledOptionSelected ? true : someSelected ? 'indeterminate' : false}
                id="select-all"
              />
              <Text>(Selecionar todos)</Text>
            </Option>
          )}
          {filterOptions?.map(item => {
            if (!item?.enabled) return;
            return (
              <Option
                data-testid={`${structureType}-filter-option`}
                title={item.name}
                key={item.name}
                value={item.code}
                label={item.name}
              >
                <FilterCheckbox selected={item.selected} id={`${structureType}-${item.code}`} />
                <Text>{item.name}</Text>
              </Option>
            );
          })}
        </Select>
      )}
    </Flex>
  );
}
