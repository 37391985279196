import { percent } from '@/shared/format';
import { Flex, Text } from '@grupoboticario/flora-react';
import clsx from 'clsx';

export function GoalAndRealized({ goal, achievement, isLoading }) {
  return (
    <>
      <Text
        className={clsx(isLoading && 'loading-placeholder loading-placeholder-subtitle')}
        size="exceptionsAuxiliarMedium"
        color="$nonInteractiveAuxiliar"
        css={{ textTransform: 'uppercase' }}
      >
        Meta
      </Text>
      {!isLoading && (
        <Flex align="center">
          <Text
            color="$nonInteractivePredominant"
            size="bodyLargeStandardMedium"
            css={{
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {goal}
          </Text>
          <Text
            color={achievement >= 100 ? '$success-standard' : '$error-standard'}
            css={{ minWidth: '10%', marginLeft: '8px', fontSize: '14px' }}
          >
            {achievement !== undefined ? percent.format(achievement / 100) : ''}
          </Text>
        </Flex>
      )}
    </>
  );
}
