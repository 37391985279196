import { useRef, useEffect, useCallback } from 'react';
import { init, getInstanceByDom } from 'echarts';
import type { CSSProperties } from 'react';
import type { EChartsOption, ECharts, SetOptionOpts } from 'echarts';

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: 'light' | 'dark';
  onClick?: (params: unknown) => void;
}
export const defaultColorPalette = {
  lightBlue: '#8495D3',
  blue: '#364372',
  violet: '#7E41B9',
  orange: '#EB9D03',
  yellow: '#E5CF32',
  lightGreen: '#7DBE78',
  green: '#52886D',
  brown: '#753F16',
  red: '#C85035',
  pink: '#DD87C2',
} as const;

export function ReactECharts({ option, style, settings, loading, onClick }: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>();

  const handleOnClick = useCallback(
    event => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  useEffect(() => {
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    chart.on('click', handleOnClick);

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    return () => {
      chart.off('click', handleOnClick);
      window.removeEventListener('resize', resizeChart);
      chart.dispose();
    };
  }, [handleOnClick]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);

      if (loading) {
        chart.showLoading({
          text: '',
          color: '#264FEC',
        });
      } else {
        chart.hideLoading();
      }
    }
  }, [loading]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      const localOption = {
        ...option,
        textStyle: {
          ...option.textStyle,
        },
      } satisfies ReactEChartsProps['option'];

      chart.setOption(localOption, settings);
    }
  }, [option, settings]);

  return <div ref={chartRef} style={{ ...style }} />;
}
